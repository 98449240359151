// Library Imports
import { useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";
import { Col, Card, Skeleton, Typography, Space, Tag, Image } from "antd";
import NextImage from "next/image";

// Assets, Media & StyleSheets 🚀
import "./ProductHorizontalCard.scss";
import DefaultImage from "../../../../../assets/images/defaultImages/DefaultProductImage.jpg";
import Points from "../../../../../assets/images/logos/sanaullaPoints.svg";
import { removeCommasAndConvertToNumber } from "../../../../../functions/HelperFunctions/index";

import Link from "next/link";

const { Meta } = Card;
const { Text } = Typography;

const ProductHorizontalCard = (props) => {
  // States
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const [hoverProduct, setHoverProduct] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    props?.product.images[0] + "%3Fwidth%3D440"
  );
  const { isDesktop, isMobile } = props?.userAgent;

  // ⬇ Redux States
  const defaultCountry = useSelector(
    (state) => state.multiLocation?.defaultCountry
  );
  const defaultCurrency = useSelector(
    (state) => state.multiLocation?.defaultCurrency
  );
  const discountPercentage = () => {
    const originalPrice = removeCommasAndConvertToNumber(
      product?.variant_detail?.original_price
    );
    const discountedPrice = removeCommasAndConvertToNumber(
      product?.variant_detail?.discounted_price
    );

    if (originalPrice && discountedPrice && originalPrice !== 0) {
      const percentageOff = Math.round(
        ((originalPrice - discountedPrice) / originalPrice) * 100
      );
      return `${percentageOff}% OFF`;
    }

    return "";
  };

  const CD_status = useMemo(
    () => product?.features?.find((feature) => feature?.name === "CD Status"),
    [product?.features]
  );

  const DeliveryTime = useMemo(
    () =>
      product?.features?.find((feature) => feature?.name === "Delivery Time"),
    [product?.features]
  );

  function extractDateAfterHyphen(deliveryDateString) {
    const parts = deliveryDateString?.split(" - ");
    return parts?.length > 1 ? parts[1] : null;
  }

  // Example delivery date from backend
  const deliveryDate = product?.delivery_date;
  const dateAfterHyphen = extractDateAfterHyphen(deliveryDate);

  function parseDate(dateString) {
    if (!dateString) {
      console.error("Invalid date string:", dateString);
      return null;
    }

    const parts = dateString.trim().split(" ");
    if (parts.length !== 3) {
      console.error("Date string does not match expected format:", dateString);
      return null;
    }

    const day = parts[0];
    const month = parts[1];
    const year = parts[2];
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const monthIndex = monthNames.indexOf(month);

    return new Date(year, monthIndex, day);
  }

  function currentFormatDate() {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = months[currentDate.getMonth()];
    const year = currentDate.getFullYear();

    return `${day} ${month}, ${year}`;
  }

  function isDateWithin10DaysOfDelivery(currentDateStr, deliveryDateStr) {
    const currentDate = parseDate(currentDateStr);
    const deliveryDate = parseDate(deliveryDateStr);

    if (!currentDate || !deliveryDate) {
      return false;
    }

    const diffTime = Math.abs(currentDate - deliveryDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays <= 10;
  }

  const handleImageError = () => {
    setImageSrc(props?.product.images[0]);
  };

  const currentDateStr = currentFormatDate();
  useEffect(() => {
    setProduct(props?.product);
    setLoading(props?.product ? false : true);
  }, [product, defaultCountry, props?.product, props?.type]);

  return (
    <Col
      className="ProductHorizontalCard"
      style={{ paddingLeft: 5, paddingRight: 5, width: "100%" }}
    >
      <Link href={"/" + product?.handle}>
        <Card
          hoverable
          cover={
            <div style={{ position: "relative" }}>
              <Image
                preview={false}
                alt={product?.title}
                width={100}
                onError={handleImageError}
                src={imageSrc}
                onMouseOver={() => {
                  if (product?.images?.length > 1) {
                    setHoverProduct(true);
                  }
                }}
                onMouseOut={() => setHoverProduct(false)}
              />
              {product?.sold_out && (
                <div
                  style={{
                    backgroundColor: "#37011e",
                    height: "20px",
                    color: "white",
                    textAlign: "center",
                    position: "absolute",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: "10px",
                    top: "62px",
                    width: "100%",
                    display: "flex",
                  }}
                >
                  Sold Out
                </div>
              )}
            </div>
          }
        >
          <Skeleton loading={loading} cover active>
            <Meta
              title={
                <Space direction="vertical">
                  {removeCommasAndConvertToNumber(
                    product?.variant_detail?.discounted_price
                  ) <
                    removeCommasAndConvertToNumber(
                      product?.variant_detail?.original_price
                    ) && (
                    <>
                      <Tag
                        className={
                          !isMobile ? "discount_tag" : "mobile_discount_tag"
                        }
                      >
                        <Text className={isMobile && "font11"}>
                          {discountPercentage > 1
                            ? discountPercentage()
                            : discountPercentage()}
                        </Text>
                      </Tag>
                    </>
                  )}
                  <Text className="product_title font11">{product?.title}</Text>
                </Space>
              }
              description={
                <Space direction="vertical" className="width100">
                  <Space>
                    <Text
                      className={
                        !isMobile ? "product_price" : "product_price font14"
                      }
                    >
                      <span>{defaultCurrency + " "}</span>
                      <span>
                        {product?.variant_detail?.discounted_price
                          ? product?.variant_detail?.discounted_price
                          : product?.variant_detail?.original_price}
                      </span>
                    </Text>
                    {removeCommasAndConvertToNumber(
                      product?.variant_detail?.discounted_price
                    ) <
                      removeCommasAndConvertToNumber(
                        product?.variant_detail?.original_price
                      ) && (
                      <Text delete type="secondary">
                        {product?.variant_detail?.original_price}
                      </Text>
                    )}
                    {/* {product?.variant_detail?.discounted_price <
                      product?.variant_detail?.original_price &&
                    product?.variant_detail?.discounted_price !==
                      product?.variant_detail?.original_price &&
                    product?.variant_detail?.discounted_price !== 0 ? (
                      <>
                        <Text delete type="secondary">
                          {defaultCurrency +
                            ". " +
                            product?.variant_detail?.original_price}
                        </Text>
                      </>
                    ) : null} */}

                    {/* {product?.loyalty_points > 0 && (
                      <Space className="marginLeft5">
                        <div
                          style={{
                            height: "20px",
                            display: "flex",
                            padding: "0 5px",
                            minWidth: "58px",
                            alignItems: "center",
                            background: "#2A1E5C",
                            borderRadius: "9px 0px 9px 9px",
                          }}
                        >
                          <NextImage
                            width={13}
                            height={15}
                            src={Points}
                            alt="LoyaltyPoints"
                          />
                          <span
                            className="font11Bold"
                            style={{ color: "white", paddingLeft: "5px" }}
                          >
                            {product?.loyalty_points}
                          </span>
                        </div>
                      </Space>
                    )} */}
                  </Space>

                  <Space className="product_tags_container">
                    {product?.online_only && (
                      <Tag className="onlineOnly_tag">
                        <Text className={isMobile && "font8"}>Online Only</Text>
                      </Tag>
                    )}
                    {product?.free_shipping && (
                      <Tag className="freeShipping_tag">
                        <Text className={!isDesktop && "font8"}>
                          Free Shipping
                        </Text>
                      </Tag>
                    )}

                    {CD_status?.value === "CN" &&
                      (product?.pre_order ? (
                        <>
                          {isDateWithin10DaysOfDelivery(
                            currentDateStr,
                            dateAfterHyphen
                          ) ? (
                            <Tag className="freeShipping_tag">
                              <Text className={!isDesktop && "font8"}>
                                Ship: {dateAfterHyphen.split(",")[0]}
                              </Text>
                            </Tag>
                          ) : null}
                        </>
                      ) : (
                        <Tag className="expressDelivery_tag">
                          <Text className={!isDesktop && "font8"}>
                            Express Delivery
                          </Text>
                        </Tag>
                      ))}

                    {CD_status?.value === "CD" && DeliveryTime?.value <= 10 && (
                      <Tag className="freeShipping_tag">
                        <Text className={!isDesktop && "font8"}>
                          {"Ship: " + dateAfterHyphen.split(",")[0]}
                        </Text>
                      </Tag>
                    )}
                  </Space>
                </Space>
              }
            />
          </Skeleton>
        </Card>
      </Link>
    </Col>
  );
};

export default ProductHorizontalCard;
