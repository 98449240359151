"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";
import { Col, Breadcrumb, Typography } from "antd";

//? Assets --------------------------------------------------------------------->
import "./BreadCrumbs.scss";

//? Modules -------------------------------------------------------------------->
// import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function BreadCrumbs({ userAgent, type, title, data }) {
  //? Hooks ---------------------------------------------------------------------->
  // const { isMobile, isTablet, isDesktop } = MediaQueryHook();
  // const { isMobile, isTablet, isDesktop } = userAgent;
  const handleURL = (value) => {
    // return value?.toLowerCase().replace(/ /g, '-')
    let splitTitle = title?.split(value);
    if (splitTitle[0].length > 0) {
      return (splitTitle[0] + value)?.toLowerCase().replace(/ /g, "-");
    } else {
      return value?.toLowerCase().replace(/ /g, "-");
    }
  };
  return (
    <Col flex="auto">
      <Breadcrumb
        className="breadCrumbs"
        separator="-"
        items={[
          {
            title: (
              <Link href="/">
                <Text>Home</Text>
              </Link>
            ),
          },
          {
            title: data?.brand && <Text>{"Brands"}</Text>,
          },
          {
            title: data?.brand ? (
              <Link href={data?.brand_handle}>
                <Text>{data?.brand}</Text>
              </Link>
            ) : (
              <Text>{type}</Text>
            ),
          },
          ...(title?.includes("/")
            ? title?.split("/").map((crumb, index) => ({
                title: (
                  <Link href={"/" + handleURL(crumb)} key={index}>
                    <Text>{crumb}</Text>
                  </Link>
                ),
              }))
            : [{ title: <Text>{title}</Text> }]),
        ]}
      />
    </Col>
  );
}
