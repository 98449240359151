"use client";

import React from "react";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../redux/Slices/multiLocationSlice";

import dynamic from "next/dynamic";

//? Assets --------------------------------------------------------------------->
import "../../components/Homepage/HomePage.scss";

//? Modules -------------------------------------------------------------------->
import TabsSection from "@/components/Homepage/TabsSection/TabsSection";
import BannerSwiper from "@/components/Homepage/BannerSwiper/BannerSwiper";
import BannerCarousel from "@/components/Homepage/BannerCarousel/BannerCarousel";
import CategoriesHeader from "@/components/Homepage/CategoriesHeader/CategoriesHeader";
const MultiUsableComp = dynamic(() =>
  import("@/components/Homepage/MultiUsableComp/MultiUsableComp")
);

const ProductsCarousel = dynamic(() =>
  import("@/components/shared/ProductsCarousel/ProductsCarousel")
);

const BrandsCollection = dynamic(() =>
  import("@/components/Homepage/BrandsCollection/BrandsCollection")
);

const ProductsGroupComp = dynamic(() =>
  import("@/components/Homepage/ProductsGroupComp/ProductsGroupComp")
);

const CataloguesCarousel = dynamic(() =>
  import("@/components/Homepage/CataloguesCarousel/CataloguesCarousel")
);

const FilterByGenderSection = dynamic(() =>
  import("@/components/Homepage/FilterByGenderSection/FilterByGenderSection")
);

const BannerSection = dynamic(() =>
  import("@/components/Homepage/BannerSection/BannerSection")
);

const LandingPage = ({ homePageData, userAgent }) => {
  const { isMobile, isTablet, isDesktop } = userAgent;
  console.log("homePageData", homePageData);
  return (
    <div className="landing-page-inner">
      {homePageData?.homepage?.map((section, index) => {
        return (
          <div key={index}>
            {section?.type === "tabs_section" && (
              <TabsSection
                data={section}
                userAgent={{ isMobile, isTablet, isDesktop }}
              />
            )}

            {section?.type === "banner_slider" &&
              (!isMobile ? (
                <div className="marginBottom15">
                  <BannerSwiper data={section} />
                </div>
              ) : (
                <BannerCarousel data={section} />
              ))}

            {section?.type === "catalogues_carousel" && (
              <div
                style={{
                  maxWidth: "1232px",
                  margin: isDesktop ? "36px auto" : "",
                }}
              >
                <CataloguesCarousel
                  data={section}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                />
              </div>
            )}

            {section?.type === "products_carousel" && (
              <div
                className="product-carousel-main"
                style={{
                  margin: "auto",
                  maxWidth: "100%",
                }}
              >
                <ProductsCarousel
                  data={
                    section?.select_type === "collections_section"
                      ? section?.collections[0].products
                      : section?.select_type === "categories_section"
                      ? section?.categories[0].products
                      : null
                  }
                  title={section?.title}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                  type={"homepage"}
                />
              </div>
            )}

            {section?.type === "banner_section" && (
              <div>
                <BannerSection data={section} userAgent={userAgent} />
              </div>
            )}

            {section?.type === "brands_slider" && (
              <div>
                <BrandsCollection data={section} userAgent={userAgent} />
              </div>
            )}

            {section?.type === "multi_usable_section" && (
              <div>
                <MultiUsableComp
                  data={section}
                  userAgent={{ isMobile, isTablet, isDesktop }}
                />
              </div>
            )}

            {section?.type === "category_filters" && (
              <div>
                <FilterByGenderSection data={section} userAgent={userAgent} />
              </div>
            )}

            {section?.type === "collections_section" && (
              <div>
                <ProductsGroupComp data={section} userAgent={userAgent} />
              </div>
            )}

            {section?.type === "categories_header_section" && !isDesktop && (
              <div>
                <CategoriesHeader data={section} userAgent={userAgent} />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default LandingPage;
