"use client";
//? Library Imports ------------------------------------------------------------>
import {
  Row,
  Col,
  Tabs,
  Image,
  Space,
  Table,
  Avatar,
  Divider,
  Typography,
} from "antd";
import Link from "next/link";
import Parser from "html-react-parser";
import { List, ListItem, ListItemText } from "@mui/material";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function ProductDescription({
  Color,
  Fabric,
  userAgent,
  ProductDetail,
}) {
  //? Hooks ---------------------------------------------------------------------->
  const { isMobile, isTablet, isDesktop } = userAgent;

  // console.log(ProductDetail?.variants[0]?.sku);
  const dataSource = [
    {
      key: "0",
      name: <Text>SKU </Text>,
      description: (
        <Text style={{ color: "#A1A1A1" }}>
          {ProductDetail?.variants[0]?.sku
            ? ProductDetail?.variants[0]?.sku
            : "N/A"}
        </Text>
      ),
    },
    {
      key: "1",
      name: <Text>Brand</Text>,
      description: ProductDetail?.brand?.map((brand, key) => {
        return (
          <Link href={"/" + brand?.handle} key={key}>
            <Text style={{ color: "#A1A1A1" }}>
              {brand?.title ? brand?.title : "N/A"}
            </Text>
          </Link>
        );
      }),
    },
    {
      key: "2",
      name: <Text>Color</Text>,
      description: (
        <Text style={{ color: "#A1A1A1" }}>
          {Color?.value ? Color?.value : "N/A"}
        </Text>
      ),
    },
    {
      key: "3",
      name: <Text>Shirt Fabric</Text>,
      description: (
        <Text style={{ color: "#A1A1A1" }}>
          {Fabric?.value ? Fabric?.value : "N/A"}
        </Text>
      ),
    },
  ];

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      width: 50,
    },

    {
      dataIndex: "description",
      key: "description",
      width: 150,
    },
  ];

  const items = [
    {
      label: (
        <Text className={!isDesktop ? "font14Bold" : "font16Bold"}>
          Product Detail
        </Text>
      ),
      key: "item-1",
      children: (
        <Space
          direction="vertical"
          style={{ width: "100%", columnGap: "20px" }}
        >
          <Text className="font14">
            {Parser(`${ProductDetail?.short_description}`)}
          </Text>
          <Table
            bordered={true}
            columns={columns}
            pagination={false}
            showHeader={false}
            dataSource={dataSource}
          />
          <Text>{Parser(`${ProductDetail?.description}`)}</Text>
        </Space>
      ),
    }, // remember to pass the key prop
    !ProductDetail?.options[0]?.values && {
      label: (
        <Text className={!isDesktop ? "font14Bold" : "font16Bold"}>
          Stitching Info
        </Text>
      ),
      key: "item-2",
      children: (
        <>
          <Text className="font14">
            In most cases, we stitch the suits as per the catalog image.
            However, some variances might occur due to our modest stitching
            practices listed below.
          </Text>

          <List className="font14">
            <ListItem className="font14">
              <Text className="font14">
                1. See-through suits are lined completely apart from the
                sleeves. Lining in the sleeves is applied upon the customer’s
                selection of the sleeve lining option.
              </Text>
            </ListItem>
            <ListItem className="font14">
              <Text className="font14">
                2. Sleeveless suits are stitched with full sleeves.
              </Text>
            </ListItem>
            <ListItem className="font14">
              <Text className="font14">
                3. Deep front and back necklines shown in the model image are
                stitched as per standard modest depth.
              </Text>
            </ListItem>
            <ListItem>
              <Text className="font14">
                4. Finished stitched products may have a slight variation of 0.5
                to 1.5 inch in measurements due to fabric nature, fabric
                availability, design requirements and stitching techniques.
              </Text>
            </ListItem>
          </List>

          <Text className="font14">
            You can always email your stitching specifications to{" "}
            <Link href="mailto:stitching@cs.sanaullastore.com">
              <Text as="span">stitching@cs.sanaullastore.com</Text>
            </Link>
          </Text>
        </>
      ),
    },
    {
      label: (
        <Text className={!isDesktop ? "font14Bold" : "font16Bold"}>
          Return Policy
        </Text>
      ),
      key: "item-3",
      children: (
        <Text className="font14 return-policy-tab-content">
          1. Any complaints made after the 14-day time period will not be
          entertained for an exchange or return. <br />
          2. Unwashed, unworn, wrong, or defective items may be exchanged within
          the same brand (depending upon stock availability) within 14 days from
          the date of delivery. <br />
          3. Any product that is &apos;Stitched&apos; for the customer will NOT
          be entertained for exchange or return.
          <br />
          4. Similarly, any unstitched product purchased from Sanaulla will not
          be open for any claims or exchanges if the customer has already had it
          stitched, even in the case of missing pieces.
          <br />
          5. Shipping costs (both ways) for sending any non-damaged items will
          be covered by the customer. Sanaulla will assume responsibility for
          shipping costs related to defective or wrong merchandise, excluding
          international orders. In the case of international orders, the
          concerned team will conduct an investigation and provide a resolution
          based on the situation.
          <br />
          6. Sanaulla will not accept exchanges for any item that has been
          washed, dried, damaged, or stained in any way. This includes items
          that have been damaged or shrunk in a dryer. Sanaulla is not
          responsible for bleeding, damage to print, or fading of colors due to
          washing.
          <br />
          7. Items offered in our Sale and Discount sections will NOT be
          entertained for exchange or return.
          <br />
          8. Product add-ons (matching trousers/dupatta) are not eligible for
          exchange.
          <br />
        </Text>
      ),
    },
  ];

  return (
    <Col span={24} className="ProductDescription">
      <Row gutter={[0, 20]}>
        {/* // * Catalog Section ---------------------> */}

        <Col
          span={24}
          className={
            isMobile || isTablet ? "borderNone" : "border borderRadius5"
          }
        >
          <Row
            style={{
              width: "100%",
              alignItems: "center",
            }}
            gutter={[8]}
            className={
              isMobile || isTablet
                ? "backgroundLight paddingLeft10 paddingRight10 paddingTop5 paddingBottom5 flexBetween borderRadius5"
                : "padding10"
            }
          >
            <Col
              // flex="12%"
              flex={isDesktop ? "10%" : "12%"}
              // style={{ textAlign: "center" }}
            >
              <Avatar
                size={44}
                icon={
                  <Image
                    alt="Avatar"
                    preview={false}
                    src={ProductDetail?.images[0]?.cdn_link}
                  />
                }
              />
            </Col>

            <Col
              // flex="1 1 auto"
              flex={isDesktop ? "90%" : "83%"}
            >
              <Space
                className="gap5"
                direction="vertical"
                style={{ width: "100%" }}
              >
                <Space
                  style={{
                    rowGap: "1px",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    className={
                      isMobile || isTablet
                        ? "font14Bold poppinsMedium"
                        : "font18Bold"
                    }
                  >
                    Catalog
                  </Text>
                </Space>

                <Divider className="margin0" />

                {ProductDetail?.collection?.map((collection, key) => {
                  return (
                    <Link href={"/" + collection?.handle} key={key}>
                      <Text className={!isDesktop ? "font12" : ""}>
                        {collection?.title ? collection?.title : "N/A"}
                      </Text>
                    </Link>
                  );
                })}
              </Space>
            </Col>
          </Row>
        </Col>

        {/* // * Category Section ---------------------> */}

        <Col
          span={24}
          className={
            isMobile || isTablet
              ? "borderNone backgroundLight paddingTop15 paddingBottom15 paddingLeft10 paddingRight10"
              : "border borderRadius5 padding10"
          }
        >
          <Text
            // className={
            //   isMobile || isTablet
            //     ? "font16Bold marginBottom5"
            //     : "font16Bold marginBottom5"
            // }
            className={
              !isDesktop
                ? "font14Bold marginBottom5 poppinsMedium"
                : "font16Bold marginBottom5"
            }
          >
            Categories
          </Text>

          <br />

          {ProductDetail?.category
            ? ProductDetail?.category?.map((cat, index) => {
                return (
                  <Link
                    href={"/" + cat?.handle}
                    key={index}
                    className="textLeft"
                  >
                    <Text className="font12">{cat?.title},&nbsp;</Text>
                  </Link>
                );
              })
            : "N/A"}
        </Col>

        <Col
          span={24}
          className={
            isMobile
              ? "paddingTop0 padding10 border borderRadius5 backgroundLight product-tabs"
              : "padding10 border borderRadius5 product-tabs"
          }
        >
          {/* ProductDetail?.options?.length */}
          <Tabs items={items} />
        </Col>
      </Row>
    </Col>
  );
}
