"use client";

import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Dropdown, List } from "antd";
import "./MobileFiltersBottomNav.scss";

import filterIcon from "../../assets/images/collection page/feather_filter.svg";
import sortAmount from "../../assets/images/collection page/sort_amount.svg";
import { AiFillCloseCircle } from "react-icons/ai";
import MobileFiltersDrawer from "./MobileFiltersDrawer";
import Image from "next/image";

// sort icons
import topSellersIcon from "../../assets/images/collection page/mobile/sort icons/top seller.svg";
import discountIcon from "../../assets/images/collection page/mobile/sort icons/discount.svg";
import priceHtoLIcon from "../../assets/images/collection page/mobile/sort icons/hightolow.svg";
import priceLtoHIcon from "../../assets/images/collection page/mobile/sort icons/lowtohigh.svg";
import handpickIcon from "../../assets/images/collection page/mobile/sort icons/handpick.svg";
import popularityIcon from "../../assets/images/collection page/mobile/sort icons/popularity.svg";

import topSellersSelectedIcon from "../../assets/images/collection page/mobile/sort icons/top seller selected.svg";
import discountSelectedIcon from "../../assets/images/collection page/mobile/sort icons/discount selected.svg";
import priceHtoLSelectedIcon from "../../assets/images/collection page/mobile/sort icons/hightolow selected.svg";
import priceLtoHSelectedIcon from "../../assets/images/collection page/mobile/sort icons/lowtohigh selected.svg";
import handpickSelectedIcon from "../../assets/images/collection page/mobile/sort icons/handpick selected.svg";
import popularitySelectedIcon from "../../assets/images/collection page/mobile/sort icons/popularity selected.svg";

const { Text } = Typography;

export default function MobileFiltersBottomNav({
  allFilters,
  setAllFilters,
  appendToUrl,
  clearFilters,
  fetchProducts,
  // onLoad,

  handleSelectedFilter,
  selectedFilters,
  setSelectedFilters,
  totalPrice,
  setTotalPrice,
  onSortChange,
  collectionDataClient
}) {
  const [showMobileFilters, setShowMobileFilters] = useState(false);
  const [sortDropdownCounter, setSortDropdownCounter] = useState(1)
  // const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const sortItemsData = [
    {
      icon: topSellersIcon,
      selected_icon: topSellersSelectedIcon,
      label: "Top Seller",
      handle: "sellers",
    },
    {
      icon: discountIcon,
      selected_icon: discountSelectedIcon,
      label: "Discount",
      handle: "discounts",
    },
    {
      icon: priceHtoLIcon,
      selected_icon: priceHtoLSelectedIcon,
      label: " Price: High to Low ",
      handle: "price_des",
    },
    {
      icon: priceLtoHIcon,
      selected_icon: priceLtoHSelectedIcon,
      label: "Price: Low to High",
      handle: "price_asc",
    },
    {
      icon: handpickIcon,
      selected_icon: handpickSelectedIcon,
      label: "Handpicked",
      handle: "manual",
    },
    {
      icon: popularityIcon,
      selected_icon: popularitySelectedIcon,
      label: "Popularity",
      handle: "newest",
    },
  ];
  const [sortQueryString, setSortQueryString] = useState("newest");

  const items = [
    {
      label: (
        <div className="flexCenter mobile-sort-header">
          <p style={{}}>Sort By</p>
          <AiFillCloseCircle />
        </div>
      ),
    },
    {
      // dynamic loop on items
      label: (
        <List
          size="small"
          className="mobile-sort-list"
          dataSource={sortItemsData}
          renderItem={(item, index) => (
            <List.Item
              style={{
                padding: "4px 0px",
                fontSize: "12px",
              }}
              className={sortQueryString == item.handle && "selected-sort-item"}
            >
              <Row align="middle" justify={"space-between"}>
                <Image
                  src={
                    sortQueryString === item.handle
                      ? item.selected_icon
                      : item.icon
                  }
                  alt="sort icon"
                />
                <Text onClick={() => onSelectSortValue(item)}>
                  {item.label}
                </Text>
              </Row>
            </List.Item>
          )}
        />
      ),
    },
  ];

  const onSelectSortValue = (item) => {
    setSortQueryString(item.handle)
    onSortChange(item.handle)
    // setSortQueryString(item.handle);
    // fetchProducts(1, 15, item.handle);
  };
  const handleSortValue = () => {
    if (sortDropdownCounter === 1) {
      setSortQueryString(collectionDataClient?.sort)
      setSortDropdownCounter(2)
    }
  }
  useEffect(function () {
    setSortQueryString(collectionDataClient?.sort)
  }, [])
  
  const handleDropdownVisibleChange = (visible) => {
    if (!visible) {
      // Call your function when the dropdown is closed
      handleDropdown("close");
    }
  };
  return (
    <Row className="mobile-filters-bottom-nav">
      {/* <Col span={12} className="flexCenter sort-by-mobile" onClick={(e) => handleSortValue()}> */}
        <Dropdown
        className="flexCenter sort-by-mobile"
          menu={{ items }}
          trigger={["click"]}
          placement="top"
          overlayClassName="mobile-filters-dropdown"
          autoFocus={false}
          onClick={(e) => handleSortValue()}
          // onVisibleChange={handleDropdownVisibleChange}
          // visible={isDropdownOpen}
        >
          <div className="nav-link">
            <Image src={sortAmount} alt="sort icon" />
            <Text>Sort</Text>
          </div>
        </Dropdown>
      {/* </Col> */}

      <Col span={12} className="flexCenter">
        <div
          className="nav-link filter-button"
          onClick={() => setShowMobileFilters(!showMobileFilters)}
        >
          <Image src={filterIcon} alt="filter icon" />
          <Text>Filter</Text>
        </div>

        <MobileFiltersDrawer
          allFilters={allFilters}
          setAllFilters={setAllFilters}
          appendToUrl={appendToUrl}
          clearFilters={clearFilters}
          //  onLoad={onLoad}
          // sortQueryString={sortQueryString}
          showFilters={showMobileFilters}
          setShowFilters={setShowMobileFilters}
          handleSelectedFilter={handleSelectedFilter}
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
        />
      </Col>
    </Row>
  );
}
