"use client";
//? Library Imports ------------------------------------------------------------>
import Link from "next/link";

import { useState, useEffect, useMemo } from "react";
import { Carousel, Col, Skeleton, Image } from "antd";
import { ZoomInOutlined } from "@ant-design/icons";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

//? Assets --------------------------------------------------------------------->
import "./ImagesCarousel.scss";
import DefaultImage from "../../../../../assets/images/defaultImages/DefaultProductImage.jpg";
// import Image from "next/image";

export default function ImagesCarousel({ Images, settings, ProductDetail }) {
  //? States --------------------------------------------------------------------->
  const [Loading, setLoading] = useState(true);
  const [ImagesList, setImagesList] = useState([]);
  const [imageLoaded, setImageLoaded] = useState({});
  const [imageSrcs, setImageSrcs] = useState({});
  //? Hooks ---------------------------------------------------------------------->
  const NoOfSlides = useMemo(() => Images?.length, [Images]);

  const onImageLoad = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };

  //? Life Cycles ---------------------------------------------------------------->
  useEffect(() => {
    setImagesList(Images);
    setLoading(false);
  }, [Images]);

  const handleImageError = (index, originalSrc) => {
    setImageSrcs((prevSrcs) => ({
      ...prevSrcs,
      [index]: originalSrc || DefaultImage, // Fallback to original src or default image
    }));
  };

  useEffect(() => {
    setImagesList(Images);
    setLoading(false);
    // Initialize imageSrcs state with modified URLs
    const initialSrcs = Images?.reduce(
      (acc, img, index) => ({
        ...acc,
        [index]: img?.cdn_link ? img.cdn_link + "%3Fwidth%3D640" : DefaultImage,
      }),
      {}
    );
    setImageSrcs(initialSrcs);
  }, [Images]);

  //? Mobile Image Carousel Settings --------------------------------------------->
  const setting = {
    speed: 500,
    swipe: true,
    dots: false,
    arrows: false,
    infinite: true,
    slidesToScroll: 1,
    adaptiveHeight: true,
    slidesToShow: NoOfSlides > 1 ? 1.1 : 1,

    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: NoOfSlides > 1 ? 1.1 : 1,
        },
      },
    ],
  };

  return (
    <Col span={24} className="carousel_container ImagesCarousel">
      <Skeleton loading={!Object.keys(imageLoaded).length === NoOfSlides}>
        <Carousel {...(settings || setting)}>
          {Images?.map((slide, index) => (
            <Link key={index} href={slide?.link ? slide?.link : ""}>
              <div style={{ position: "relative" }}>
                {!imageLoaded[index] && (
                  <div
                    style={{
                      width: "100%",
                      height: "470px",
                      backgroundColor: "white",
                      position: "relative",
                      // marginLeft: "5px",
                      top: 0,
                      left: 0,
                      zIndex: 1,
                    }}
                  />
                )}
                <div
                  className="enlargeButton"
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    right: "10px",
                    bottom: "10px",
                    zIndex: 1,
                  }}
                  onClick={() =>
                    NativeFancybox.show(
                      Images?.map((img) => ({
                        src: img?.cdn_link,
                        type: "image",
                      })),
                      {
                        startIndex: index,
                      }
                    )
                  }
                >
                  <ZoomInOutlined className="iconStyle" />
                  <span className="hoverText">Click to enlarge</span>
                </div>
                <Image
                  width="100%"
                  height="auto"
                  preview={false}
                  onLoad={() => onImageLoad(index)}
                  onClick={() =>
                    NativeFancybox.show(
                      Images?.map((img) => ({
                        src: img?.cdn_link,
                        type: "image",
                      })),
                      {
                        startIndex: index,
                      }
                    )
                  }
                  className="borderRadius5"
                  src={imageSrcs[index]}
                  onError={() => handleImageError(index, slide?.cdn_link)}
                  alt={ProductDetail?.title || "Default Image"}
                  title={ProductDetail?.title || "Default Image"}
                  style={{
                    opacity: imageLoaded[index] ? 1 : 0,
                    zIndex: 2,
                    display: !imageLoaded[index] ? "none" : "block",
                  }}
                />
              </div>
            </Link>
          ))}
        </Carousel>
      </Skeleton>
    </Col>
  );
}
