"use client";

import {
  Drawer,
  Typography,
  Collapse,
  Input,
  Col,
  Row,
  Checkbox,
  Slider,
} from "antd";
import React, { useState, useEffect, useRef, use } from "react";
import { BiSearch } from "react-icons/bi";

import "./FiltersDrawer.scss";

// media
import maleIcon from "../../assets/images/collection page/filters/male_icon.svg";
import femaleIcon from "../../assets/images/collection page/filters/female_icon.svg";
import boyIcon from "../../assets/images/collection page/filters/boy_icon.svg";
import girlIcon from "../../assets/images/collection page/filters/girl_icon.svg";
import babyIcon from "../../assets/images/collection page/filters/baby_icon.svg";
import { useRouter } from "next/navigation";
import Image from "next/image";
// Library Constants
const { Text } = Typography;
const { Search } = Input;

export default function FiltersDrawer({
  showFilters,
  setShowFilters,
  allFilters,
  setAllFilters,
  appendToUrl,
  clearFilters,
  getFilters,
  fetchProducts,
  activePage,
  selectedFilters,
  setSelectedFilters,
  setTotalPrice,
  handleSelectedFilter,
  setCursor,
}) {
  const firstRender = useRef(true);
  const { Panel } = Collapse;

  let defaultMin = "";
  let defaultMax = "";

  const [newMin, setNewMin] = useState("");
  const [newMax, setNewMax] = useState("");
  const [showFilterSearch, setShowFilterSearch] = useState([]);
  const [searchFiltersResult, setFiltersSearchResult] = useState(allFilters);
  const [searchedFilterType, setSearchedFilterType] = useState(null); // when user search for a filter , this state will set to the fiter type
  const [showMoreOptions, setShowMoreOptions] = useState([]);

  // make list of objs with type and value [{index,shows}]

  let status = false;

  // let tempSelectedFilters = allFilters?.map((filter) => {
  //   return {
  //     type: filter.type,
  //     value: [],
  //   };
  // });

  const router = useRouter();

  //const [selectionBehaviour, setSelectionBehaviour] = useState([]); // too keep record for the user selection behaviour

  // useEffect(() => {
  //   if (!firstRender.current) {
  //     FiltersQuery();
  //   }
  //   firstRender.current = false;
  // }, [newMin, newMax]);

  // useEffect(() => {
  //   return () => {};
  // }, [onLoadQuery]);

  // const filterProducts = (e, name, value) => {

  //   // setting selected filters

  //   let flag = false;

  //   let tempFilters = selectedFilters;

  //   if (e.target.checked === true) {
  //     if (!tempFilters.length) {
  //       tempFilters.push({ type: name, value: [value] });
  //     } else {
  //       for (let index = 0; index < tempFilters.length; index++) {
  //         if (name === tempFilters[index].type) {
  //           flag = true;
  //           tempFilters[index].value.push(value);
  //           tempFilters[index].latest_select = true;
  //         } else {
  //           tempFilters[index].latest_select = false;
  //         }
  //       }
  //       if (flag === false) {
  //         tempFilters.push({
  //           type: name,
  //           value: [value],
  //         });
  //       }
  //     }
  //   } else {
  //     for (let index = 0; index < tempFilters.length; index++) {
  //       if (name === tempFilters[index].type) {
  //         tempFilters[index].value.splice(
  //           tempFilters[index].value.indexOf(value),
  //           1
  //         );
  //         tempFilters[index].latest_select = false;
  //       }
  //     }
  //   }
  //   setSelectedFilters(tempFilters);
  //   handleFiltersQuery();

  //   // making list of selected filters to be sent to the post api body

  //   let updatedSelectedFilters = tempFilters?.map((filter) => {
  //     if (name === filter.type) {
  //       return { ...filter, latest_select: true };
  //     } else {
  //       return { ...filter, latest_select: false };
  //     }
  //   });
  // };

  // console.log("selectionBehaviour", selectionBehaviour);

  const clearAllFilters = () => {
    // setOnLoadQuery("");
    setCursor(null);
    let tempSelectedFilters = Object.assign([], selectedFilters);
    for (let i = 0; i < tempSelectedFilters.length; i++) {
      tempSelectedFilters[i].value = [];
    }
    setSelectedFilters(Object.assign([], tempSelectedFilters));
    setNewMin("");
    setNewMax("");
    // setTotalPrice("");
    clearFilters();
  };

  const displayFilters = () => {
    return allFilters?.map((filter, index) => {
      // setFiltersSearchResult(filter?.data);

      // while mapping all filter data, check if the updated filter data is available in searchFiltersResults
      if (filter.type === "cd_status") {
        return null;
      }

      let filterData = filter?.data;
      let moreFilterData = [];

      if (filter?.data?.length > 5) {
        // some filters does not have more than 5 options i.e. availability
        filterData = filter?.data?.slice(0, 5);
        moreFilterData = filter?.data?.slice(5, filter?.data?.length);
      }
      // if show more of any filter type is clicked
      if (showMoreOptions.includes(filter?.type)) {
        filterData = filter?.data;
      }

      // if the user search for an options, then show the search result`
      if (searchFiltersResult?.length && searchedFilterType === filter?.type) {
        const findFilter = searchFiltersResult.find(
          (f) => f.type === filter.type
        );
        filterData = findFilter?.data;
        if (showMoreOptions.includes(filter?.type)) {
          // if the user has pressed show more options then show all the options else only the first few
          filterData = findFilter?.data;
        } else {
          filterData = findFilter?.data?.slice(0, 5);
          moreFilterData = findFilter?.data?.slice(5, findFilter?.data?.length);
        }
      }

      if (filter.type !== "price" && filter?.data?.length) {
        return (
          <>
            <Panel
              header={filter.title}
              key={index}
              showArrow={false}
              className="panel-main"
            >
              <span className="panel-search-icon">
                <BiSearch
                  onClick={() => {
                    const searchList = showFilterSearch;
                    searchList[index] = !showFilterSearch[index];
                    setShowFilterSearch([...searchList]);
                  }}
                />
              </span>

              {showFilterSearch[index] && (
                <Search
                  placeholder="Search..."
                  style={{
                    // width: 255,
                    background: "#ffffff",
                  }}
                  onChange={(e) => {
                    if (e.target.value.length === 0) {
                      // empty search of particular filter
                      //setFiltersSearchResult([]);
                    }

                    onSearchFilters(e.target.value, filter);
                    setSearchedFilterType(filter.type);
                  }}
                  allowClear
                  Button={false}
                />
              )}
              {filterData?.map((singleFilter, j) => {
                //status = onLoadQuery.includes(singleFilter?.handle);

                return (
                  <Row key={j}>
                    <Col span={24}>
                      <Checkbox
                        // type="checkbox"
                        key={j}
                        onChange={(e) => {
                          if (filter?.type === "category") {
                            // navigate("/" + singleFilter.handle);
                            router.push("/" + singleFilter.handle);
                            setShowFilters(false);
                          } else {
                            //filterProducts(e, filter.type, singleFilter.handle);
                            handleSelectedFilter(
                              e,
                              filter.type,
                              singleFilter.handle
                            );
                          }
                        }}
                        // defaultChecked={status}
                        checked={selectedFilters?.find(
                          (selectedFilter) =>
                            selectedFilter.type === filter.type &&
                            selectedFilter.value.includes(singleFilter.handle)
                        )}
                        name={filter?.type}
                      >
                        {filter?.type === "color" && (
                          <div
                            style={{ background: singleFilter?.hex }}
                            className="color-option-container"
                          ></div>
                        )}

                        {filter?.type === "gender" && (
                          <div className="gender-option-container">
                            <Image
                              src={
                                singleFilter.name.toLowerCase() === "men" ||
                                singleFilter.name.toLowerCase() === "male"
                                  ? maleIcon
                                  : singleFilter.name.toLowerCase() ===
                                      "women" ||
                                    singleFilter.name.toLowerCase() === "female"
                                  ? femaleIcon
                                  : singleFilter.name
                                      .toLowerCase()
                                      .includes("boy")
                                  ? boyIcon
                                  : singleFilter.name
                                      .toLowerCase()
                                      .includes("girl")
                                  ? girlIcon
                                  : singleFilter.name
                                      .toLowerCase()
                                      .includes("baby")
                                  ? babyIcon
                                  : boyIcon
                              }
                              alt="gender icon"
                            />
                          </div>
                        )}

                        {console.log(singleFilter.name)}
                        {singleFilter.name.toLowerCase() === "cn"
                          ? "Express Delivery"
                          : singleFilter.name}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}

              {filterData?.length !== filter?.data?.length &&
              !showMoreOptions?.includes(filter?.type) &&
              moreFilterData?.length ? (
                <Col
                  onClick={() => {
                    if (!showMoreOptions.includes(filter?.type)) {
                      setShowMoreOptions([...showMoreOptions, filter?.type]);
                    }
                  }}
                  className="show-more-button"
                >
                  + {moreFilterData?.length} More
                </Col>
              ) : null}
            </Panel>
          </>
        );
      }

      /* if (filter.type === "price") {
          f
            defaultMin = filter.min;
            defaultMax = filter.max;
          
          return (
            <Panel header={filter.title} key={i}>
              <Input.Group compact className="price-filters">
                <Input
                  style={{
                    width: 100,
                    textAlign: "center",
                  }}
                  type="number"
                  placeholder="Minimum"
                  name="minimum"
                  value={newMin}
                  onChange={priceFilterChange}
                />
                <Input
                  className="site-input-split"
                  style={{
                    width: 30,
                    borderLeft: 0,
                    borderRight: 0,
                    pointerEvents: "none",
                  }}
                  placeholder="~"
                  disabled
                />
                <Input
                  name="maximum"
                  className="site-input-right"
                  style={{
                    width: 100,
                    textAlign: "center",
                  }}
                  type="number"
                  placeholder="Maximum"
                  value={newMax}
                  onChange={priceFilterChange}
                />
              </Input.Group>
            </Panel>
          );
        } */
      const priceHeader = (
        <div className="price-header">
          <span> {filter.title} </span>
          <div className="apply-reset-price">
            <button
              className="reset"
              onClick={(e) => resetPrice(e, filter?.type)}
            >
              Reset
            </button>
            <button
              className="apply"
              onClick={(e) => applyPrice(e, filter?.type)}
            >
              Apply
            </button>
          </div>
        </div>
      );
      if (filter?.type === "price" && filter?.max) {
        defaultMin = filter.min;
        defaultMax = filter.max;

        return (
          <Panel
            header={priceHeader}
            key={index}
            showArrow={false}
            className="price-filter price-main"
          >
            <Slider
              className="price-slider"
              range={{
                draggableTrack: true,
              }}
              defaultValue={[defaultMin, defaultMax]}
              //dots={true}
              // range={true}
              min={defaultMin}
              max={defaultMax}
              onChange={(value) => {
                onChangeSlider(filter?.type, value);
              }}
              value={[newMin, newMax]}
              //tooltip={{ open: true, visible: true, placement: "bottom" }}
              tooltip={{ visible: true, placement: "bottom" }}
            />
            <Row className="paddingTop30">
              <Input.Group compact className="price-filter flexCenter">
                <Input
                  className="price-input-field"
                  type="number"
                  placeholder="From"
                  name="minimum"
                  value={newMin ? newMin : defaultMin}
                  onChange={(e) => priceFilterChange(e, filter?.type)}
                />
                {/* <Input className="input-field-split" placeholder="~" disabled /> */}
                <span className="input-field-split">-</span>
                <Input
                  className="price-input-field"
                  name="maximum"
                  type="number"
                  placeholder="To"
                  value={newMax ? newMax : defaultMax}
                  onChange={(e) => priceFilterChange(e, filter?.type)}
                />
              </Input.Group>
            </Row>
          </Panel>
        );
      }
    });
  };

  const priceFilterChange = (e, filterType) => {
    let currentMin = newMin;
    let currentMax = newMax;
    if (e.target.name === "minimum") {
      currentMin = e.target.value;
      setNewMin((prevMin) => {
        // if (currentMin < defaultMin) {
        //   return defaultMin;
        // }
        // if (!currentMin) {
        //   return defaultMin;
        // }
        return currentMin;
      });
    } else {
      currentMax = e.target.value;
      setNewMax((prevMax) => {
        // if (currentMax > defaultMax) {
        //   return defaultMax;
        // }
        // if (!currentMax) {
        //   return defaultMax;
        // }
        return currentMax;
      });
    }
    //setTotalPrice("&price=" + currentMin + "-" + currentMax);
    // console.log("totalPrice", totalPrice);

    // handleSelectedFilter(null, filterType, currentMin + "-" + currentMax);
  };

  const onChangeSlider = (filterType, value) => {
    setNewMin(value[0]);
    setNewMax(value[1]);
    // handleSelectedFilter(null, filterType, value[0] + "-" + value[1]);
    // setTotalPrice("&price=" + value[0] + "-" + value[1]);
  };

  const applyPrice = (e, filterType) => {
    let handleNewMin = newMin;
    let handleNewMax = newMax;
    if (handleNewMin === "") {
      handleNewMin = defaultMin;
    }
    if (handleNewMax === "") {
      handleNewMax = defaultMax;
    }
    e.stopPropagation();
    handleSelectedFilter(null, filterType, handleNewMin + "-" + handleNewMax);
  };
  const resetPrice = (e, filterType) => {
    e.stopPropagation();
    setNewMin(defaultMin);
    setNewMax(defaultMax);
    handleSelectedFilter(null, filterType, defaultMin + "-" + defaultMax);
  };

  const onSearchFilters = (value, filter) => {
    // results for search results of particular filter and allFiltersUpdated for updating the search results in all filters
    const results = [];
    const allFiltersUpdated = [];

    // setting the search results to the data
    filter?.data?.map((item) => {
      if (item?.name?.toLowerCase().includes(value.toLowerCase())) {
        results.push(item);
      }
    });

    // setting the updated data against the search in any filter
    allFilters?.map((item, index) => {
      if (item.type === filter.type) {
        allFiltersUpdated[index] = { ...item, data: results };
      } else {
        allFiltersUpdated[index] = {
          ...item,
          data: searchFiltersResult[index]?.data,
        };
      }
    });

    setFiltersSearchResult(allFiltersUpdated);
  };

  const onCloseFiltersDrawer = () => {
    setShowFilters(false);
  };

  // useEffect(() => {
  //   console.log("selectedFilters useeffect");
  //   getFilters();
  // }, [selectedFilters]);

  // useEffect(() => {
  //   // if page is changed then calling this function with filter type and pager number

  // }, [activePage]);
  useEffect(() => {
 console.log("allFilters", allFilters)
  }, [allFilters])
  

  const FilterHeader = () => {
    return (
      <div className="desktop-filter-header">
        <div class="ant-drawer-title">Filter</div>
        <button
          className="reset-all-filters"
          onClick={(e) => clearAllFilters()}
        >
          Clear Filters
        </button>
      </div>
    );
  };
  return (
    <Drawer
      rootClassName="filters-drawer"
      // title="Filter"
      title={FilterHeader()}
      placement="left"
      width={300}
      onClose={onCloseFiltersDrawer}
      open={showFilters}
      // extra={
      //   <Space>
      //     <Text
      //       type={"danger"}
      //       onClick={onCloseFiltersDrawer}
      //       style={{ cursor: "pointer" }}
      //     >
      //       Cancel
      //     </Text>
      //   </Space>
      // }
    >
      <Col lg={{ span: 24 }}>
        <Collapse
          accordion={false}
          expandIconPosition="right"
          bordered={false}
          showArrow={false}
          // onChange={() =>
          //   setShowFilterSearch({
          //     indexes: [showFilterSearch?.indexes,index],
          //     show: false,
          //   })
          // }

          defaultActiveKey={allFilters?.map((filter, index) => index)}
        >
          {displayFilters()}
        </Collapse>
      </Col>
    </Drawer>
  );
}
