"use client";

//? Library Imports ------------------------------------------------------------>
import axios from "axios";
import NextImage from "next/image";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useEffect, useState, useRef, useMemo } from "react";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import {
  Row,
  Col,
  Space,
  Button,
  Select,
  Typography,
  Pagination,
  Spin,
  Image,
  Switch,
  Tag,
} from "antd";

//? Assets --------------------------------------------------------------------->
import InfiniteScroll from "react-infinite-scroll-component";
import "./CollectionPage.scss";
import { FaFilter } from "react-icons/fa";
import robot from "../../assets/images/defaultImages/robot.jpg";

import gridIcon_1 from "../../assets/images/collection page/grid_1.svg";
import gridIcon_2 from "../../assets/images/collection page/grid_2.svg";
import gridIcon_3 from "../../assets/images/collection page/grid_3.svg";
import gridIcon_4 from "../../assets/images/collection page/grid_4.svg";
import gridIcon_5 from "../../assets/images/collection page/grid_5.svg";
import gridIcon_row from "../../assets/images/collection page/grid_row.svg";

import gridDarkIcon_1 from "../../assets/images/collection page/grid_dark_1.svg";
import gridDarkIcon_2 from "../../assets/images/collection page/grid_dark_2.svg";
import gridDarkIcon_3 from "../../assets/images/collection page/grid_dark_3.svg";
import gridDarkIcon_4 from "../../assets/images/collection page/grid_dark_4.svg";
import gridDarkIcon_5 from "../../assets/images/collection page/grid_dark_5.svg";
import gridDarkIcon_row from "../../assets/images/collection page/grid_dark_row.svg";
//? Modules -------------------------------------------------------------------->
import FiltersDrawer from "./FiltersDrawer";
import BreadCrumbs from "../Shared/BreadCrumbs/BreadCrumbs";
import MobileFiltersBottomNav from "./MobileFiltersBottomNav";
import ProductCard from "../Shared/ProductsCarousel/ProductCard/ProductCard";
import ProductHorizontalCard from "../Shared/ProductsCarousel/ProductCard/ProductHorizontalCard/ProductHorizontalCard";
import {
  changeCountryId,
  changeCountry,
  changeCountryCode,
  changeCurrency,
} from "../../redux/Slices/multiLocationSlice";
import CustomSkeletonBox from "../CustomSkeletonBox/CustomSkeletonBox";
import useBrowserBackButton from "../globalBackButton/BackButton";
import ScrollToTopComponent from "../scrollToTopHook/ScrollToTop";
import { removeCommasAndConvertToNumber } from "@/functions/HelperFunctions";
import LandingPage from "@/components/CollectionPage/LandingPage";
import { ThunderboltOutlined } from "@ant-design/icons";
import FlashIcon from "../../assets/images/flashIcon/flashIcon.svg";

//? Library Constants ---------------------------------------------------------->
const { Text, Title } = Typography;

export default function CollectionPage({
  landingPageDataServer,
  handle,
  pageType,
  userAgent,
  filtersData,
  productsList,
  totalProducts,
  storeId,
  serverSortOption,
  collectionData,
  clientDetail,
  defaultCookiesCurrencyValue,
  defaultCookiesCountryValue,
  next_cursor,
  prev_cursor,
  url_has_cursor,
  itemListName,
  itemListID,
}) {
  if (!Cookies.get("defaultCurrencyId")) {
    Cookies.set("defaultCurrencyId", storeId, {
      expires: 365,
    });
  }
  if (!Cookies.get("defaultCurrencyName")) {
    Cookies.set("defaultCurrencyName", defaultCookiesCurrencyValue, {
      expires: 365,
    });
  }
  if (!Cookies.get("defaultCountry")) {
    Cookies.set("defaultCountry", defaultCookiesCountryValue, {
      expires: 365,
    });
  }
  if (!Cookies.get("geoLocation")) {
    Cookies.set("geoLocation", defaultCookiesCountryValue, {
      expires: 365,
    });
  }
  console.log("clientDetail", clientDetail)
  const router = useRouter();
  const searchParams = useSearchParams();
  const defaultCurrencyIdFromCookies =
    storeId || Cookies.get("defaultCurrencyId");
  const defaultCurrencyFromCookies = Cookies.get("defaultCurrencyName");

  const [products, setProducts] = useState(productsList);
  const [totalProductsCount, setTotalProductsCount] = useState(totalProducts);
  const [productsListingDetail, setProductsListingDetail] = useState({
    count: totalProducts,
  });
  const [resetFilterFlag, setResetFilterFlag] = useState(false);
  const [pageCursor, setpageCursor] = useState({
    next: null,
    previous: null,
  });
  const [loading, setLoading] = useState(false);
  const [pageNumberSeo, setpageNumberSeo] = useState(1);
  const [allFilters, setAllFilters] = useState(filtersData);
  const [cursor, setCursor] = useState(1);
  const [sortFilter, setSortFilter] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [showLoader, setShowLoader] = useState(true);
  const [showFilter, setShowFilter] = useState(true);
  const [productsLimit, setProductsLimit] = useState(24);
  const [sortOption, setSortOption] = useState(serverSortOption || "newest");
  const [OrderOption, setOrderOption] = useState("asc");
  const { isDesktop, isMobile } = userAgent;
  const [gridView, setGridView] = useState(isDesktop ? 4 : 2);
  const [showDropDownValues, setShowDropDownValue] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [selectedFiltersList, setSelectedFiltersList] = useState([]);
  const [defaultCountry, setDefaultCountry] = useState(storeId);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const [clientApi, setClientApi] = useState(false);
  const [collectionPayload, setCollectionPayload] = useState(null);
  const [sortUpdateCounter, setSortUpdateCounter] = useState(1);
  const [collectionDataClient, setCollectionDataClient] = useState(null);
  const [viewCategory, setViewCategory] = useState(true);
  const [callEvent, setCallEvent] = useState(false);
  const [landingPageData, setLandingPageData] = useState(landingPageDataServer);
  const topElementRef = useRef(null);
  const landingPageRef = useRef(null);
  const [landingPageClickEvent, setLandingPageClickEvent] = useState({});
  const [cdStatus, setCdStatus] = useState("CD");
  const [brandBannerImage, setBrandBannerImage] = useState(
    collectionData?.banner_image
  );
  const [isExpress, setIsExpress] = useState(false);

  const [appendToURLFlag, setAppendToURLFlag] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsExpress(window.location.href.includes("cd_status=CN") ? true : false);
      // const url = new URL(window.location.href);
      // if (url.searchParams.get("cd_status") !== "CN") {
      //   url.searchParams.delete("cd_status");
      //   window.history.replaceState({}, "", url.toString());
      // }
      // console.log(url.searchParams.get("cd_status"))
    }
  });
  // useBrowserBackButton(() => { });

  let sortQueryValue = "newest";
  let defaultCountryId;

  console.log(selectedFilters);

  const handleToggle = (checked) => {
    if (checked === true) {
      setActivePage(1);
      setCursor(null);
      setCdStatus("CN");
      handleSelectedFilter(false, "cd_status", "CN");
    } else {
      // if (window.location.href.includes("cd_status")) {
      //   const url = new URL(window.location.href);
      //   console.log(url.searchParams.get("cd_status"))
      //   if (url.searchParams.get("cd_status")) {

      setActivePage(1);
      setCursor(null);
      setCdStatus("CD");
      handleSelectedFilter(false, "cd_status", "CD");
    }
    // }
    setIsExpress(checked);
  }

  useEffect(() => {
    if (window.history.pushState) {
      const url = new URL(window.location);
      url.searchParams.delete("page");
      window.history.pushState({ path: url.toString() }, "", url.toString());
    }
  }, []);
  const fetchData = async (url) => {
    try {
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      return await response.json();
    } catch (error) {
      console.error("API call error:", error);
      throw error;
    }
  };

  const initializeData = async () => {
    try {
      const geoLocationUrl =
        "https://pro.ip-api.com/json/?fields=61439&key=FmwVTqAjxMiJk3z";
      const countryDataUrl = `${process.env.NEXT_PUBLIC_BACKEND}/storefront/store_list`;

      const apiData = await fetchData(geoLocationUrl);

      const countryListData = await fetchData(countryDataUrl);

      const matchingCountry = countryListData?.country_list.find(
        (countryItem) => countryItem.country === apiData.country
      );
      if (matchingCountry) {
        Cookies.set("defaultCurrencyName", matchingCountry.currency, {
          expires: 365,
        });

        Cookies.set("defaultCurrencyId", matchingCountry.id, {
          expires: 365,
        });

        setDefaultCountry(matchingCountry.id);
        defaultCountryId = matchingCountry.id;

        Cookies.set("defaultCountry", matchingCountry.country, {
          expires: 365,
        });

        Cookies.set("geoLocation", matchingCountry.country, {
          expires: 365,
        });

        dispatch(setDefaultCountry(matchingCountry));
        dispatch(changeCountryId(matchingCountry.id));
        dispatch(changeCountry(matchingCountry.country));
        dispatch(changeCountryCode(matchingCountry.short_code));
        dispatch(changeCurrency(matchingCountry.currency));
      } else {
        Cookies.set("defaultCurrencyName", "USD", {
          expires: 365,
        });

        Cookies.set("defaultCurrencyId", "35", {
          expires: 365,
        });
        setDefaultCountry("35");
        Cookies.set("defaultCountry", "United States", {
          expires: 365,
        });

        Cookies.set("geoLocation", apiData.country, {
          expires: 365,
        });
        defaultCountryId = "35";
        dispatch(setDefaultCountry("United States"));
        dispatch(changeCountryId("35"));
        dispatch(changeCountry("United States"));
        dispatch(changeCurrency("USD"));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handlePageChange = (page, pageSize) => {
    setAppendToURLFlag(true);

    setActivePage(page);
    fetchProducts(page, pageSize);
    scrollToTop();
  };

  useEffect(() => {
    const fetchDataAndInitialize = async () => {
      await initializeData();

      await fetchProducts(activePage, productsLimit, handle, false);

      await getFilters(pageType, handle);
    };

    if (storeId === null || !defaultCurrencyIdFromCookies) {
      fetchDataAndInitialize();
      scrollToTop();
      setLoading(true);
    }
    if (!window?.location?.href.includes("?")) {
      const newPath = `${window?.location?.origin}/${handle}`;
      history.pushState({}, "", newPath);
    }
  }, []);

  useEffect(() => {
    if (collectionData) {
      setSortOption(collectionData?.sort);
      setCollectionDataClient(collectionData);
      setBrandBannerImage(collectionData?.banner_image);
      if (!window?.location?.href.includes("?")) {
        getFilters();
      }
    }
    if (clientApi && !appendToURLFlag) {
      setLoading(true);

      fetchProducts(activePage, productsLimit, handle, false);
      // handlePageChange(activePage, productsLimit);
      getFilters(pageType, handle);
    }
  }, [activePage, appendToURLFlag, serverSortOption, cdStatus]);

  const fetchProducts = async (
    page,
    pageSize,
    sortValue,
    cursorNullFlag,
    clearFilterFlag
  ) => {
    let sortQueryString = "";
    let finalParam =
      process.env.NEXT_PUBLIC_BACKEND + "/storefront/product_list?";

    if (OrderOption) {
    } else {
      sortQueryString = "&sort=" + sortQueryValue;
    }
    let queryParas = window.location.search.split("?")[1];
    let cursorParam = null;
    let appendUrl = `${pageType}_handle=${handle}`;
    if (page && !cursorNullFlag) {
      cursorParam = page;
    }
    console.log(queryParas);
    if (!queryParas?.includes("limit")) {
      finalParam += `limit=${productsLimit}&`;
    }

    finalParam +=
      appendUrl +
      sortQueryString +
      `&storefront=${defaultCountryId ?? defaultCurrencyIdFromCookies}`;

    if (cursorParam && clearFilterFlag === undefined) {
      if (queryParas) {
        if (queryParas.includes("page=")) {
          queryParas = queryParas.split("page")[0];
          finalParam += `&${queryParas}` + `page=${cursorParam}`;
        } else {
          finalParam += `&${queryParas}` + `&page=${cursorParam}`;
        }
      } else {
        finalParam += `&page=${cursorParam}`;
      }

      if (window.location.search.includes("page")) {
        let splittedValue = window.location.search;
        splittedValue = splittedValue.split("page")[0];
        console.log(window.location);
        if (
          window.location.search.includes("size") ||
          window.location.search.includes(
            "price" ||
            window.location.search.includes("color") ||
            window.location.search.includes("brand") ||
            window.location.search.includes("hide_out_of_stock") ||
            window.location.search.includes("cd_status")
          )
        ) {
          window.history.pushState(
            null,
            null,
            splittedValue + `page=${cursorParam}`
          );
        } else {
          window.history.pushState(
            null,
            null,
            window.location.pathname + splittedValue + `page=${cursorParam}`
          );
        }
      } else {
        if (
          window.location.search.includes("size") ||
          window.location.search.includes(
            "price" ||
            window.location.search.includes("color") ||
            window.location.search.includes("brand") ||
            window.location.search.includes("hide_out_of_stock") ||
            window.location.search.includes("cd_status")
          )
        ) {
          window.history.pushState(
            null,
            null,
            window.location.pathname +
            window.location.search +
            `&page=${cursorParam}`
          );
        } else {
          if (resetFilterFlag) {
            window.history.pushState(null, null, window.location.pathname);
            setResetFilterFlag(false);
          } else {
            if (queryParas) {
              window.history.pushState(
                null,
                null,
                window.location.pathname + `?${queryParas}&page=${cursorParam}`
              );
            } else {
              window.history.pushState(
                null,
                null,
                window.location.pathname + `?page=${cursorParam}`
              );
            }
          }
        }
      }
    } else {
      if (clearFilterFlag) {
        setResetFilterFlag(false);
      }
      finalParam += `&${queryParas}`;
    }
    axios
      .get(finalParam)
      .then((response) => {
        setCallEvent(true);
        // setProducts(response?.data?.data);
        if (!cursorNullFlag) {
          let prevProducts = products;
          let newProducts = response?.data?.data;
          let mergedProducts = newProducts;
          setProducts(mergedProducts);
        } else {
          scrollToTop();
          setProducts(response?.data?.data);
        }
        let cursorObject = {
          next: null,
          previous: null,
        };
        let splitterPrev = null;
        let splitterNext = null;
        if (response?.data?.next) {
          cursorObject.next = (response?.data?.next)
            .split("page=")[1]
            .split("&")[0];
        } else {
          cursorObject.next = null;
        }
        if (response?.data?.previous) {
          cursorObject.previous = (response?.data?.previous)
            .split("page=")[1]
            .split("&")[0];
        } else {
          cursorObject.previous = null;
        }

        setpageCursor({ ...cursorObject });

        if (response?.data?.next) {
          setCursor(response?.data?.next);
          if (clearFilterFlag) {
            setpageNumberSeo(1);
          } else {
            setpageNumberSeo(pageNumberSeo + 1);
          }
        } else {
          setCursor(null);
        }
        setProductsListingDetail({
          title: response?.title,
          count: response?.data?.count,
        });
        setTotalProductsCount(response?.data?.count);
        if (sortUpdateCounter === 1 && !storeId) {
          setCollectionDataClient(response?.data);
          setSortOption(response?.data?.sort);
          setSortUpdateCounter(2);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  // useEffect(() => {
  //   if (clientApi) {
  //     <ScrollToTopComponent />;
  //     setLoading(true);
  //   }
  // }, [clientApi]);

  // useEffect(() => {
  //   scrollToTop()
  // }, []);

  const getFilters = async () => {
    let queryParas = window.location.search.split("?")[1];
    let appendUrl = queryParas
      ? `${pageType}_handle=${handle}` + "&" + queryParas
      : `${pageType}_handle=${handle}`;

    axios
      .get(
        process.env.NEXT_PUBLIC_BACKEND +
        "/storefront/filter_list?" +
        appendUrl +
        `&storefront=${defaultCountryId ?? defaultCurrencyIdFromCookies}`
      )

      .then((response) => {
        setAllFilters(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSortChange = (value) => {
    if (defaultCountryId !== null) {
      setClientApi(true);
      setSortOption(value);
      setActivePage(1);
      scrollToTop();
      setLoading(true);
      setCursor(null);
      setProducts([]);
      handleSelectedFilter(false, "sort", value);
    }
  };

  useEffect(() => {
    setShowDropDownValue([
      { value: "24", label: "24" },
      { value: "48", label: "48" },
      { value: "72", label: "72" },
    ]);
  }, []);

  const onChangeGridView = (gridValue) => {
    setGridView(gridValue);
    if (gridValue === 2 || gridValue === -1) {
      setProductsLimit(24);
      setShowDropDownValue([
        { value: "24", label: "24" },
        { value: "48", label: "48" },
        { value: "72", label: "72" },
      ]);
    }
    if (gridValue === 3) {
      setProductsLimit(24);
      setShowDropDownValue([
        { value: "24", label: "24" },
        { value: "48", label: "48" },
        { value: "72", label: "72" },
      ]);
    }
    if (gridValue === 4) {
      setProductsLimit(24);
      setShowDropDownValue([
        { value: "24", label: "24" },
        { value: "48", label: "48" },
        { value: "72", label: "72" },
      ]);
    }
    if (gridValue === 5) {
      setProductsLimit(24);
      setShowDropDownValue([
        { value: "24", label: "24" },
        { value: "48", label: "48" },
        { value: "72", label: "72" },
      ]);
    }
  };

  const appendToUrl = (query) => {
    setActivePage(1);
    let urlToAppend = "";

    if (query !== "") {
      urlToAppend = "?" + query.slice(1);
    } else {
      urlToAppend = "/" + handle;
    }
    debugger

    window.history.pushState(null, null, urlToAppend);
    console.log("urlToAppend", urlToAppend);
    router.push(urlToAppend, undefined, { shallow: true });
    if (defaultCountry !== null) {
      setAppendToURLFlag(true);

      fetchProducts(1, productsLimit, undefined, true);
      setpageNumberSeo(1);
      getFilters();
    }
  };

  const clearFilters = () => {
    setResetFilterFlag(true);
    let newUrl = window.location.href.split("?");
    window.history.replaceState({}, document.title, newUrl[0]);
    setActivePage(1);
    if (defaultCountryId !== null) {
      scrollToTop();
      setLoading(true);
      setAppendToURLFlag(true);
      fetchProducts(1, productsLimit, undefined, true, true);
      getFilters();
    }
  };

  const handleChangeShowItems = (value) => {
    if (defaultCountryId !== null) {
      setProductsLimit(value);
      setCursor(null);
      setProducts([]);
      setActivePage(1);
      handleSelectedFilter(false, "limit", value);
    }
  };

  const showFiltersDrawer = () => {
    setShowFilters(true);
  };

  const handleSelectedFilter = (e, name, value) => {
    scrollToTop();
    setLoading(true);
    setClientApi(true);
    debugger
    let tempSelectedFilters = selectedFilters;
    let presentFlag = false;
    let pagePresentFlag = false;

    if (
      name !== "price" &&
      name !== "page" &&
      name !== "limit" &&
      name !== "sort" &&
      name !== "cd_status"
    ) {
      if (e.target.checked === true) {
        for (let index = 0; index < tempSelectedFilters.length; index++) {
          if (tempSelectedFilters[index]?.type === "page") {
            pagePresentFlag = true;
          }
        }

        if (pagePresentFlag) {
          for (let index = 0; index < tempSelectedFilters.length; index++) {
            if ("page" === tempSelectedFilters[index].type) {
              tempSelectedFilters[index].value = [1];
            }
          }
        }

        for (let index = 0; index < tempSelectedFilters.length; index++) {
          if (name === tempSelectedFilters[index].type) {
            presentFlag = true;
          }
        }

        if (presentFlag) {
          for (let index = 0; index < tempSelectedFilters.length; index++) {
            if (name === tempSelectedFilters[index].type) {
              tempSelectedFilters[index].value.push(value);
            }
          }
        } else {
          tempSelectedFilters.push({
            type: name,
            value: [value],
          });
        }
      } else {
        for (let index = 0; index < tempSelectedFilters.length; index++) {
          //
          if (name === tempSelectedFilters[index].type) {
            tempSelectedFilters[index].value.splice(
              tempSelectedFilters[index].value.indexOf(value),
              1
            );
          }
        }
      }
    } else if (
      name === "page" ||
      name === "limit" ||
      name === "sort" ||
      name === "cd_status"
    ) {
      for (let index = 0; index < tempSelectedFilters.length; index++) {
        if (name === tempSelectedFilters[index].type) {
          presentFlag = true;
        }
      }

      if (presentFlag) {
        for (let index = 0; index < tempSelectedFilters.length; index++) {
          if (name === tempSelectedFilters[index].type) {
            tempSelectedFilters[index].value = [value];
          }
        }
      } else {
        tempSelectedFilters.push({
          type: name,
          value: [value],
        });
      }
    } else {
      let priceRangePresent = false;
      if (tempSelectedFilters.length) {
        tempSelectedFilters?.map((filter) => {
          if (name === filter.type) {
            filter.value = [value];
            priceRangePresent = true;
          }
        });
      }
      if (!priceRangePresent) {
        tempSelectedFilters.push({
          type: name,
          value: [value],
        });
      }
    }
    setSelectedFilters(tempSelectedFilters);
    handleFiltersQuery();
  };

  const handleFiltersQuery = () => {
    let query = "";
    for (let index = 0; index < selectedFilters.length; index++) {
      if (selectedFilters[index].value.length) {
        query += "&";
        query += selectedFilters[index].type + "=";
        query += selectedFilters[index].value.join("%2B");
      }
    }

    appendToUrl(query + totalPrice);
  };

  const handleSelectedFiltersUrl = () => {
    let currentUrl = window.location.href;

    let urlToProcess = currentUrl.split("?")[1];
    if (urlToProcess) {
      let urlToProcessList = urlToProcess.split("&");

      let tempSelectedFilters = [];

      urlToProcessList?.map((filter) => {
        //
        let filterType = filter.split("=")[0];
        let filterValue = filter.split("=")[1]?.split("%2B");

        if (
          filterType !== "page" &&
          filterType !== "limit" &&
          filterType !== "sort" &&
          filterType !== "cd_status"
        ) {
          tempSelectedFilters.push({
            type: filterType,
            value: filterValue,
          });
        } else {
          if (filterType === "page") {
            setActivePage(parseInt(filterValue[0]));
          }
          if (filterType === "limit") {
            setProductsLimit(parseInt(filterValue[0]));
          }
          if (filterType === "sort") {
            setSortOption(filterValue[0]);
          }
          if (filterType === "cd_status") {
            // --------//
            setCdStatus(filterValue[0]);
          }
        }
      });

      setSelectedFilters(tempSelectedFilters);
      getFilters();
    }
  };
  const capitalizeEachWord = (text) => {
    return text
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const scrollToTop = () => {
    // if (topElementRef.current) {
    //   topElementRef.current.scrollIntoView({ behavior: 'smooth' });
    // }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const formattedHandle = capitalizeEachWord(handle ?? "");

  useEffect(() => {
    if (defaultCountry !== null) {
      handleSelectedFiltersUrl();
    }

    // scrollToTop()
  }, []);
  const handleCollectionTitle = (str) => {
    if (str && str?.includes("/")) {
      return str.split("/").pop();
    } else {
      return str;
    }
  };
  function processCollectionData(results) {
    const processedData = results.map((item, index) => {
      return {
        item_name: item?.title,
        item_id: item?.id,
        price: removeCommasAndConvertToNumber(
          item?.variant_detail?.discounted_price
        ),
        item_brand: item?.brand?.[0]?.title ?? "No Brand",
        item_category: itemListName,
        item_variant: item?.variant_detail?.variant_id,
        index: index,
        location_id: defaultCookiesCountryValue,
      };
    });

    return processedData;
  }

  useEffect(() => {
    if (products && callEvent) {
      localStorage.setItem("collection_name", itemListName);
      const processedResponse = processCollectionData(products);
      if (viewCategory) {
        fbq("track", "ViewCategory", {
          content_category: collectionDataClient?.title,
        });
        setViewCategory(false);
      }

      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          item_list_id: itemListID,
          item_list_name: itemListName,
          currencyCode: defaultCurrencyFromCookies
            ? defaultCurrencyFromCookies
            : "PKR",
          items: processedResponse,
        },
      });
    }
  }, [products, callEvent]);

  useEffect(() => {
    if (!window.location.href.includes("?")) {
      setCallEvent(true);
    }
  }, []);

  // useEffect(function () {
  //   if (window?.location?.pathname !== "/") {
  //     document.querySelector(".footer-seo-description").style.display = "none";
  //   } else {
  //     document.querySelector(".footer-seo-description").style.display = "block";
  //   }
  // }, []);

  useEffect(() => {
    // Remove existing link elements with rel="prev" or rel="next"
    const existingLinks = document.querySelectorAll(
      'link[rel="prev"], link[rel="next"]'
    );
    const existingMetaTags = document.querySelectorAll(
      'meta[name="robots"][content="index, follow, nocache"]'
    );
    existingLinks.forEach((link) => {
      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
    });

    if (pageCursor?.next) {
      const nextLink = document.createElement("link");
      nextLink.rel = "next";
      let nextPageValue = pageNumberSeo + 1;
      nextLink.href = `https://www.sanaullastore.com${window.location.pathname}?page=${pageCursor?.next}`;
      // Append the new nextLink element to the head of the document
      document.head.appendChild(nextLink);
    }

    if (pageCursor?.previous) {
      const prevLink = document.createElement("link");
      prevLink.rel = "prev";
      let prevPageValue = pageNumberSeo - 1;
      prevLink.href = `https://www.sanaullastore.com${window.location.pathname}?page=${pageCursor?.previous}`;
      // Append the new prevLink element to the head of the document
      document.head.appendChild(prevLink);

      const metaTagChange = document.querySelector('meta[name="robots"]');
      if (metaTagChange) {
        // Modify the content attribute of the meta element
        metaTagChange.setAttribute("content", "noindex, follow");
      }
    } else {
      const metaTagChange = document.querySelector('meta[name="robots"]');
      if (metaTagChange) {
        // Modify the content attribute of the meta element
        metaTagChange.setAttribute("content", "index, follow, nocache");
      }
    }

    // Cleanup function
    return () => {
      // Check if the prevLink exists in the DOM before removing
      if (pageNumberSeo !== 1) {
        const prevLinkToRemove = document.querySelector('link[rel="prev"]');
        if (prevLinkToRemove && prevLinkToRemove.parentNode) {
          prevLinkToRemove.parentNode.removeChild(prevLinkToRemove);
        }
      }

      const nextLinkToRemove = document.querySelector('link[rel="next"]');
      if (nextLinkToRemove && nextLinkToRemove.parentNode) {
        nextLinkToRemove.parentNode.removeChild(nextLinkToRemove);
      }
    };
  }, [pageCursor, pageNumberSeo]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (next_cursor) {
  //       let splitted_value = next_cursor?.split("page=")[1].split("&")[0];
  //       const nextLink = document.createElement("link");
  //       nextLink.rel = "next";
  //       nextLink.href = `https://www.sanaullastore.com${window.location.pathname}?page=${splitted_value}`;
  //       document.head.appendChild(nextLink);
  //     }

  //     if (prev_cursor) {
  //       let splitted_value = prev_cursor?.split("page=")[1].split("&")[0];
  //       const nextLink = document.createElement("link");
  //       nextLink.rel = "prev";
  //       nextLink.href = `https://www.sanaullastore.com${window.location.pathname}?page=${splitted_value}`;
  //       document.head.appendChild(nextLink);

  //       const metaTagChange = document.querySelector('meta[name="robots"]');
  //       if (metaTagChange) {
  //         // Modify the content attribute of the meta element
  //         metaTagChange.setAttribute("content", "noindex, follow");
  //       }
  //     }
  //   }, 100);
  // }, [next_cursor]);

  useEffect(() => {
    const handleClick = (event) => {
      // Check if the clicked element is inside landing-page-content
      console.log("landingPageRef.current", landingPageRef.current);
      console.log("landingPageRef.current", event.target);
      if (
        landingPageRef.current &&
        landingPageRef.current.contains(event.target)
      ) {
        setLoading(true);
        setLandingPageClickEvent(event);
      }
    };

    // Bind the click event listener
    document.addEventListener("click", handleClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    console.log("searchParams.toString()", searchParams.toString());
    if (
      landingPageRef.current &&
      landingPageRef.current.contains(landingPageClickEvent.target)
    ) {
      fetchProducts(activePage, productsLimit, undefined, true);
      getFilters();
      handleSelectedFiltersUrl();
      setLandingPageClickEvent({});
    }
  }, [searchParams]);
  const toggleSeeMore = () => {
    const descriptionElement = document.getElementById("seoLongDescription");
    const seeMoreButton = document.getElementById("seeMoreButton");

    if (descriptionElement.classList.contains("collapsed")) {
      descriptionElement.classList.remove("collapsed");
      seeMoreButton.textContent = "See Less";
    } else {
      descriptionElement.classList.add("collapsed");
      seeMoreButton.textContent = "See More";
    }
  };
  return (
    <>
      <Row
        className={
          !isDesktop
            ? "collection_page_wrapper collection_page_wrapper_mobile"
            : "collection_page_wrapper"
        }
      >
        <Col
          lg={{ span: 24 }}
          className={
            isDesktop
              ? "collection_page_outer"
              : "collection_page_outer width100 padding0 marginAuto"
          }
        >
          <Col lg={{ span: 24 }}>
            <Row className="main-collection-inner">
              <Col
                lg={{ span: 24 }}
                className={
                  isMobile
                    ? "padding10"
                    : isDesktop
                      ? "paddingTop20 paddingBottom20"
                      : ""
                }
              >
                <BreadCrumbs
                  type={
                    pageType === "category"
                      ? " Category "
                      : pageType === "collection"
                        ? " Collection "
                        : pageType === "brand"
                          ? " Brand "
                          : null
                  }
                  data={collectionDataClient}
                  title={formattedHandle}
                  userAgent={userAgent}
                />
              </Col>
              {(pageType === "brand" || pageType === "category") && (
                <>
                  {loading && !landingPageData && brandBannerImage ? (
                    <CustomSkeletonBox
                      width="95%"
                      height={isDesktop ? 400 : 120}
                    />
                  ) : (
                    brandBannerImage && (
                      <div className="collection-banner">
                        <Image
                          height="auto"
                          width="100%"
                          preview={false}
                          src={brandBannerImage}
                        />
                      </div>
                    )
                  )}
                </>
              )}

              {landingPageData && (
                <div
                  className="landing-page-content Homepage"
                  ref={landingPageRef}
                >
                  <LandingPage
                    homePageData={landingPageData}
                    userAgent={userAgent}
                  />
                </div>
              )}
              <Col
                ref={topElementRef}
                lg={24}
                style={{
                  background: isDesktop && "#ffffff",
                }}
                className={
                  !isDesktop
                    ? "collection_page_content padding0"
                    : "collection_page_content"
                }
              >
                <Row
                  align="middle"
                  className={
                    !isDesktop ? "mobile_header_border" : "header_border"
                  }
                >
                  {isDesktop ? (
                    <>
                      {console.log(selectedFilters)}
                      <Col lg={18}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 10,
                          }}
                        >
                          <h1 level={1} className="collection-title test-title">
                            {" "}
                            {collectionData?.title ??
                              handleCollectionTitle(formattedHandle)}{" "}
                          </h1>{" "}
                          <span className="express-delivery-outer">
                            <Tag className="expressDelivery_tag">
                              <Text
                                className={!isDesktop && "font8"}
                                style={{ color: "white", fontStyle: "italic" }}
                              >
                                Express Delivery
                              </Text>
                              <Image
                                src={FlashIcon.src}
                                height={15}
                                width="auto"
                                alt="express delivery"
                              />
                            </Tag>
                            <Switch
                              style={{ borderRadius: "20px" }}
                              checked={isExpress}
                              onChange={handleToggle}
                            />
                          </span>
                        </div>
                      </Col>
                      <Col
                        lg={isDesktop ? 6 : 24}
                        className="grid-show-wrapper"
                      >
                        <Space className="grid-icons-container">
                          {isDesktop && (
                            <>
                              <Text>View as</Text>

                              {gridView === 5 ? (
                                <NextImage
                                  src={gridDarkIcon_5}
                                  preview={false}
                                  alt="grid icon img"
                                  onClick={() => onChangeGridView(5)}
                                />
                              ) : (
                                <NextImage
                                  src={gridIcon_5}
                                  preview={false}
                                  alt="grid icon img"
                                  onClick={() => {
                                    onChangeGridView(5);
                                  }}
                                />
                              )}

                              {gridView === 4 ? (
                                <NextImage
                                  src={gridDarkIcon_4}
                                  preview={false}
                                  alt="grid icon img"
                                  onClick={() => onChangeGridView(4)}
                                />
                              ) : (
                                <NextImage
                                  src={gridIcon_4}
                                  preview={false}
                                  alt="grid icon img"
                                  onClick={() => onChangeGridView(4)}
                                />
                              )}

                              {gridView === 3 ? (
                                <NextImage
                                  src={gridDarkIcon_3}
                                  preview={false}
                                  alt="grid icon img"
                                  onClick={() => onChangeGridView(3)}
                                />
                              ) : (
                                <NextImage
                                  src={gridIcon_3}
                                  preview={false}
                                  alt="grid icon img"
                                  onClick={() => onChangeGridView(3)}
                                />
                              )}
                            </>
                          )}

                          {gridView === 2 ? (
                            <NextImage
                              src={gridDarkIcon_2}
                              preview={false}
                              alt="grid icon img"
                            />
                          ) : (
                            <NextImage
                              src={gridIcon_2}
                              preview={false}
                              alt="grid icon img"
                              onClick={() => onChangeGridView(2)}
                            />
                          )}
                        </Space>

                        <Space>
                          <Text>Show</Text>

                          <Select
                            className="flexCenter"
                            value={productsLimit}
                            style={{ width: 60 }}
                            onChange={handleChangeShowItems}
                            options={showDropDownValues}
                          />
                        </Space>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={8} className="paddingLeft10">
                        <Text className="font12">
                          <span className="poppinsSemiBold">
                            {productsListingDetail?.count}
                          </span>{" "}
                          Results
                        </Text>
                      </Col>

                      <Col span={8} className="flexCenter">
                        <Space>
                          {gridView === 2 ? (
                            <NextImage
                              src={gridDarkIcon_2}
                              preview={false}
                              alt="grid icon img"
                            />
                          ) : (
                            <NextImage
                              src={gridIcon_2}
                              preview={false}
                              alt="grid icon img"
                              onClick={() => onChangeGridView(2)}
                            />
                          )}
                          {gridView === -1 ? (
                            <NextImage
                              src={gridDarkIcon_row}
                              preview={false}
                              alt="grid icon img"
                            />
                          ) : (
                            <NextImage
                              src={gridIcon_row}
                              preview={false}
                              alt="grid icon img"
                              onClick={() => onChangeGridView(-1)}
                            />
                          )}

                          {gridView === 1 ? (
                            <NextImage
                              src={gridDarkIcon_1}
                              preview={false}
                              alt="grid icon img"
                            />
                          ) : (
                            <NextImage
                              src={gridIcon_1}
                              preview={false}
                              alt="grid icon img"
                              onClick={() => onChangeGridView(1)}
                            />
                          )}
                        </Space>
                      </Col>

                      <Col span={8} className="flexEnd paddingRight10">
                        <Space>
                          <Text className="font12">Show</Text>

                          <Select
                            className="flexCenter mobile_select_option"
                            value={productsLimit}
                            width={60}
                            height={24}
                            onChange={handleChangeShowItems}
                            options={showDropDownValues}
                          />
                        </Space>
                      </Col>
                    </>
                  )}
                </Row>
                {isMobile && (
                  <span className="express-delivery-outer" style={{ paddingTop: '10px' }} >
                    <Tag className="expressDelivery_tag">
                      <Text
                        className={!isDesktop && "font8"}
                        style={{ color: "white", fontStyle: "italic" }}
                      >
                        Express Delivery
                      </Text>
                      <Image
                        src={FlashIcon.src}
                        height={15}
                        width="auto"
                        alt="express delivery"
                      />
                    </Tag>
                    <Switch
                      style={{ borderRadius: "20px" }}
                      checked={isExpress}
                      onChange={handleToggle}
                    />
                  </span>
                )}

                {isDesktop ? (
                  <Row align="middle" justify="flex-end">
                    <Col lg={16}>
                      <Space>
                        <Button
                          icon={<FaFilter />}
                          className="filter_btn"
                          onClick={showFiltersDrawer}
                        >
                          Filter
                        </Button>

                        {allFilters && (
                          <FiltersDrawer
                            allFilters={allFilters}
                            setAllFilters={setAllFilters}
                            appendToUrl={appendToUrl}
                            clearFilters={clearFilters}
                            showFilters={showFilters}
                            setShowFilters={setShowFilters}
                            setSelectedFiltersList={setSelectedFiltersList}
                            getFilters={getFilters}
                            fetchProducts={fetchProducts}
                            activePage={activePage}
                            handleSelectedFilter={handleSelectedFilter}
                            selectedFilters={selectedFilters}
                            setSelectedFilters={setSelectedFilters}
                            totalPrice={totalPrice}
                            setCursor={setCursor}
                          />
                        )}

                        <Text className="products_count_tag">
                          Showing <span> {productsListingDetail?.count} </span>
                          Products
                        </Text>
                      </Space>
                    </Col>

                    <Col lg={4} className="flexEnd"></Col>

                    <Col lg={4} className="flexEnd">
                      <Space>
                        <Text className="font12">Sort</Text>

                        <Select
                          style={{ width: 164 }}
                          onChange={onSortChange}
                          defaultValue={sortOption}
                          className="sort_select_option"
                          value={sortOption}
                          options={[
                            {
                              value: "price_asc",
                              label: "Price (Low to High)",
                            },
                            {
                              value: "price_des",
                              label: "Price (High to Low)",
                            },
                            { value: "newest", label: "Newest" },
                            { value: "discounts", label: "Top Discounts" },
                            { value: "sellers", label: "Top Sellers" },
                            { value: "manual", label: "Handpicked" },
                          ]}
                        />
                      </Space>
                    </Col>
                  </Row>
                ) : (
                  allFilters && (
                    <MobileFiltersBottomNav
                      allFilters={allFilters}
                      setAllFilters={setAllFilters}
                      appendToUrl={appendToUrl}
                      clearFilters={clearFilters}
                      fetchProducts={fetchProducts}
                      //onLoad={onLoadQuery}
                      handleSelectedFilter={handleSelectedFilter}
                      selectedFilters={selectedFilters}
                      setSelectedFilters={setSelectedFilters}
                      totalPrice={totalPrice}
                      setTotalPrice={setTotalPrice}
                      onSortChange={onSortChange}
                      collectionDataClient={collectionDataClient}
                    />
                  )
                )}
                {collectionDataClient?.short_description?.length > 2 && (
                  <Col
                    span={24}
                    className={
                      !isDesktop
                        ? "description_container mobile_description_container "
                        : "description_container "
                    }
                  >
                    <div
                      className={!isDesktop ? "font11 " : "font14"}
                      dangerouslySetInnerHTML={{
                        __html: collectionDataClient?.short_description,
                      }}
                    />
                  </Col>
                )}
                {loading ? (
                  <div style={{ display: "flex" }}>
                    {Array.from({ length: gridView }, (_, index) => (
                      <div key={index} style={{ flex: 1, marginRight: 8 }}>
                        <CustomSkeletonBox
                          width="100%"
                          height={isMobile ? 300 : 450}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <>
                    {/* <InfiniteScroll
                      dataLength={products?.length}
                      next={fetchProducts}
                      hasMore={cursor}
                      loader={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            height: "40px",
                          }}
                        >
                          <Spin />
                        </div>
                      }
                      endMessage={
                        <p
                          className="infinite-scroll-message"
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            paddingBottom: "20px",
                            fontWeight: "bold",
                          }}
                        >
                          No more results.
                        </p>
                      }
                      scrollThreshold={0.7}
                    > */}
                    <Row justify="center">
                      <Col lg={24}>
                        {products?.length ? (
                          <Row
                            className={
                              !isMobile
                                ? "product_listing_container"
                                : " product_listing_container paddingLeft10 paddingRight10"
                            }
                            justify={isMobile && "space-between"}
                            gutter={[0, 10]}
                          // style={{ border: "1px solid pink" }}
                          >
                            {products?.length
                              ? products.map((pro, index) => {
                                return (
                                  <Col
                                    key={index}
                                    flex={
                                      gridView === 5
                                        ? "19%"
                                        : gridView === 4
                                          ? "24%"
                                          : gridView === 3
                                            ? "32.33%"
                                            : gridView === 2
                                              ? "49%"
                                              : gridView === -1
                                                ? "90%"
                                                : "70%"
                                    }
                                    style={{
                                      maxWidth:
                                        gridView === 5
                                          ? "19%"
                                          : gridView === 4
                                            ? "24%"
                                            : gridView === 3
                                              ? "32.33%"
                                              : gridView === 2
                                                ? "49%"
                                                : gridView === -1
                                                  ? "90%"
                                                  : "70%",
                                    }}
                                  >
                                    {gridView !== -1 ? (
                                      <ProductCard
                                        product={pro}
                                        keyIndex={index}
                                        userAgent={userAgent}
                                        storeCuurency={
                                          defaultCookiesCurrencyValue
                                        }
                                        pageType={"collectionPage"}
                                      />
                                    ) : (
                                      <ProductHorizontalCard
                                        product={pro}
                                        key={index}
                                        userAgent={userAgent}
                                        pageType={"collectionPage"}
                                      />
                                    )}
                                  </Col>
                                );
                              })
                              : null}
                          </Row>
                        ) : (
                          <Col span={24}>
                            <Row
                              style={{
                                gap: "10px",
                                overflow: "hidden",
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <Col span={24}>
                                <NextImage
                                  src={robot}
                                  alt="No Products Found!"
                                  preview={false}
                                  width={200}
                                  style={{ display: "inline-block" }}
                                />
                                <Text
                                  style={{
                                    display: "block",
                                    textAlign: "center",
                                    fontSize: "18px",
                                  }}
                                >
                                  No Products Found!
                                </Text>
                              </Col>
                            </Row>
                          </Col>
                        )}
                        {/* {cursor && (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                          }}
                        >
                          <Button
                            variant="contained"
                            onClick={() => {
                              fetchProducts(2, 12, "women-clothing");
                            }}
                          >
                            Load more items
                          </Button>
                        </div>
                      )} */}
                        {/* {products?.length > 0 && (
                        <Col
                          span={24}
                          className={
                            !isDesktop
                              ? "flexCenter pagination_container  pagination_container_mobile paddingBottom30 "
                              : "flexCenter pagination_container "
                          }
                        >
                          <Pagination
                            current={activePage}
                            defaultCurrent={activePage}
                            total={productsListingDetail?.count}
                            pageSize={productsLimit}
                            onChange={(page, pageSize) => {
                              setClientApi(true);
                              setActivePage(page);
                            }}
                          />
                        </Col>
                      )} */}
                        {/* {collectionDataClient?.long_description?.length > 2 && (
                        <div className="seo-long-description">
                          <div
                            className={!isDesktop ? "font11 " : "font14"}
                            dangerouslySetInnerHTML={{
                              __html: collectionDataClient?.long_description,
                            }}
                          />
                        </div>
                      )} */}
                      </Col>
                    </Row>
                    <Col span={24} className="pagination-container">
                      <Pagination
                        current={activePage}
                        total={totalProductsCount}
                        pageSize={productsLimit}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        style={{
                          textAlign: "center",
                          marginTop: "20px",
                          justifyContent: "center",
                        }}
                      />
                    </Col>
                    {/* </InfiniteScroll> */}
                    <div>
                      <div className="seo-long-description-container">
                        <div
                          id="seoLongDescription"
                          className="seo-long-description-content collapsed"
                          dangerouslySetInnerHTML={{
                            __html: collectionData?.long_description || "",
                          }}
                        />
                        {collectionData?.long_description?.length > 0 && (
                          <button
                            id="seeMoreButton"
                            className="see-more-btn"
                            onClick={toggleSeeMore}
                          >
                            Read more
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
    </>
  );
}
