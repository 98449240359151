//? Library Imports ------------------------------------------------------------>
import { Row, Col, Carousel, Typography } from "antd";

//? Assets --------------------------------------------------------------------->
import "./ProductsCarousel.scss";
import {
  SlickNextArrow,
  SlickPrevArrow,
} from "../Buttons/CarouselButtons/CarouselButtons";

//? Modules -------------------------------------------------------------------->
import ProductCard from "./ProductCard/ProductCard";
import { useEffect, useState } from "react";
import CustomSkeletonBox from "@/components/CustomSkeletonBox/CustomSkeletonBox";

//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function ProductsCarousel({
  categoryName,
  data,
  title,
  userAgent,
  type,
}) {
  //? Hooks ---------------------------------------------------------------------->
  // const [loading, setLoading] = useState(true);
  const { isMobile, isDesktop } = userAgent;
  //let noOfSlides = products?.products?.length > 5 ? true : false;

  // console.log(type);
  const settings = {
    // speed: 500,
    swipe: true,
    dots: false,
    autoplay: false,
    infinite: false,
    arrows: !isMobile,
    slidesToShow: !isDesktop ? 2.2 : 5,
    slidesToScroll: !isDesktop ? 2 : 1,
    // autoplayspeed: 1500,
    prevArrow: <SlickPrevArrow />,
    nextArrow: <SlickNextArrow />,
  };

  // useEffect(() => {
  //   if (data?.length) {
  //     setLoading(false);
  //   }
  // }, [data]);
  // localStorage.setItem("collection_name", categoryName);

  return (
    <Row
      align="middle"
      justify="center"
      className={
        !isDesktop
          ? "ProductsCarousel products-carousel-container padding0"
          : "ProductsCarousel padding0 products-carousel-container"
      }
    >
      {title ? (
        <Col
          span={24}
          className="sectionHeadingContainer text-center mt-10 mb-10"
        >
          <h2
            className={
              !isDesktop ? "sectionHeadingBold" : "sectionHeadingBold width100"
            }
          >
            {title}
          </h2>
        </Col>
      ) : null}

      <Col span={24}>
        <div
          className={
            !isDesktop
              ? "carousel_container paddingLeft5 "
              : "carousel_container "
          }
        >
          {/* {loading ? (
            <div style={{ display: "flex" }}>
              {isMobile ? (
                <>
                  {[1, 2].map((index) => (
                    <div key={index} style={{ flex: 1, marginRight: 16 }}>
                      <CustomSkeletonBox width={100} height={250} />
                    </div>
                  ))}
                </>
              ) : (
                <>
                  {[1, 2, 3, 4, 5].map((index) => (
                    <div key={index} style={{ flex: 1, marginRight: 16 }}>
                      <CustomSkeletonBox width={100} height={371} />
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : ( */}
          <Carousel {...settings}>
            {data?.length &&
              data?.map((pro, key) => {
                return (
                  <ProductCard
                    key={key}
                    type={type}
                    product={pro}
                    categoryName={categoryName}
                    userAgent={userAgent}
                  />
                );
              })}
          </Carousel>
          {/* )} */}
        </div>
      </Col>
    </Row>
  );
}
