"use client";

import {
  Button,
  Drawer,
  Space,
  Typography,
  Collapse,
  Input,
  Col,
  Row,
  Checkbox,
  Slider,
  Image,
} from "antd";
// import { show } from "antd-mobile/es/components/dialog/show";
import { filter, set } from "lodash";
import { useRouter } from "next/navigation";
import React, { useState, useEffect, useRef } from "react";
import { BiSearch } from "react-icons/bi";
// import { useNavigate } from "react-router-dom";

import "./MobileFiltersDrawer.scss";

// Library Constants
const { Text } = Typography;
const { Search } = Input;

export default function MobileFiltersDrawer({
  showFilters,
  setShowFilters,
  allFilters,
  setAllFilters,
  appendToUrl,
  clearFilters,
  //onLoad,
  handleSelectedFilter,
  selectedFilters,
  setSelectedFilters,
  totalPrice,
  setTotalPrice,
}) {
  const [filterType, setFilterType] = useState(allFilters[0].type);
  const [searchFiltersResult, setFiltersSearchResult] = useState(allFilters);

  let query = "";
  let defaultMin = "";
  let defaultMax = "";

  const [newMin, setNewMin] = useState("");
  const [newMax, setNewMax] = useState("");
  // const [totalPrice, setTotalPrice] = useState("");

  const firstRender = useRef(true);

  const router = useRouter();

  // const [selectedFilters, setSelectedFilters] = useState([]);

  // const navigate = useNavigate();

  const onCloseFiltersDrawer = () => {
    setShowFilters(false);
  };

  const displayFilter = () => {
    let filterIndex = null;
    let findFilter = {};

    allFilters?.map((filter, index) => {
      if (filter.type === filterType) {
        filterIndex = index;
        findFilter = filter;
      }
    });

    // while mapping all filter data, check if the updated filter data is available in searchFiltersResults
    // console.log("searchFiltersResult", searchFiltersResult);
    let filterData = findFilter?.data;
    if (searchFiltersResult[filterIndex]?.data?.length > 0) {
      const findFilter = searchFiltersResult.find((f) => f.type === filterType);
      filterData = findFilter?.data;
    }
    if (findFilter.type !== "price") {
      return (
        <>
          <Text
            className="reset-filters-text"
            onClick={() => clearAllFilters()}
          >
            Reset Selected
          </Text>
          <Search
            placeholder="Search..."
            style={{
              // width: 255,
              background: "#ffffff",
            }}
            onChange={(e) => {
              if (e.target.value.length === 0) {
                // empty search of particular filter
                //setFiltersSearchResult([]);
              }

              onSearchFilters(e.target.value, findFilter);
            }}
            allowClear
          />
          
          {filterData?.map((singleFilter, index) => {
            return (
              <Row key={index}>
                <Col span={24} key={index} className="checkbox-container">
                  <Checkbox
                    // type="checkbox"

                    onChange={(e) => {
                      if (filterType === "category") {
                        // navigate("/" + singleFilter.handle);
                        router.push("/" + singleFilter.handle);
                        setShowFilters(false);
                      } else {
                        //filterProducts(e, filter.type, singleFilter.handle);
                        handleSelectedFilter(
                          e,
                          filterType,
                          singleFilter?.handle
                        );
                      }
                    }}
                    // defaultChecked={status}
                    checked={selectedFilters?.find(
                      (selectedFilter) =>
                        selectedFilter.type === filterType &&
                        selectedFilter.value.includes(singleFilter.handle)
                    )}
                    name={filter?.type}
                  >
                    <Text
                      className={
                        selectedFilters
                          .find((filter) => filter.type === findFilter?.type)
                          ?.value?.indexOf(singleFilter.handle) > -1 &&
                        "checkbox-checked"
                      }
                    >
                      {singleFilter.name}
                    </Text>
                  </Checkbox>
                </Col>
              </Row>
            );
          })}
        </>
      );
    } else {
      defaultMin = findFilter.min;
      defaultMax = findFilter.max;

      return (
        // <>
        //   <Slider
        //     range={{
        //       draggableTrack: true,
        //     }}
        //     // defaultValue={[20, 50]}
        //     //dots={true}
        //     // range={true}
        //     max={filter.max}
        //     min={filter.min}
        //     onChange={(value) => {
        //       onChangeSlider(value);
        //     }}
        //     //tooltip={{ open: true, visible: true, placement: "bottom" }}
        //     tooltip={{ visible: true, placement: "bottom" }}
        //   />
        //   <Row className="paddingTop30">
        //     <Input.Group compact className="price-filter flexCenter">
        //       <Input
        //         className="price-input-field"
        //         type="number"
        //         placeholder="From"
        //         name="minimum"
        //         value={newMin}
        //         onChange={priceFilterChange}
        //       />
        //       {/* <Input className="input-field-split" placeholder="~" disabled /> */}
        //       <span className="input-field-split">-</span>
        //       <Input
        //         className="price-input-field"
        //         name="maximum"
        //         type="number"
        //         placeholder="To"
        //         value={newMax}
        //         onChange={priceFilterChange}
        //       />
        //       <button>Apply</button>
        //     </Input.Group>
        //   </Row>
        // </>
        <>
          <div className="price-mobile-container price-filter">
            <div className="price-header">
              <div className="apply-reset-price">
                <button
                  className="reset"
                  onClick={(e) => resetPrice(e, findFilter?.type)}
                >
                  Reset
                </button>
                <button
                  className="apply"
                  onClick={(e) => applyPrice(e, findFilter?.type)}
                >
                  Apply
                </button>
              </div>
            </div>
            <div className="price-container-inner">
              <Slider
                className="price-slider"
                range={{
                  draggableTrack: true,
                }}
                defaultValue={[defaultMin, defaultMax]}
                //dots={true}
                // range={true}
                min={defaultMin}
                max={defaultMax}
                onChange={(value) => {
                  onChangeSlider(filter?.type, value);
                }}
                value={[newMin, newMax]}
              //tooltip={{ open: true, visible: true, placement: "bottom" }}
              // tooltip={{ open: true, placement: "bottom" }}
              />
              <Row className="paddingTop30">
                <Input.Group compact className="price-filter flexCenter">
                  <Input
                    className="price-input-field"
                    type="number"
                    placeholder="From"
                    name="minimum"
                    value={newMin ? newMin : defaultMin}
                    onChange={(e) => priceFilterChange(e, filter?.type)}
                  />
                  {/* <Input className="input-field-split" placeholder="~" disabled /> */}
                  <span className="input-field-split">-</span>
                  <Input
                    className="price-input-field"
                    name="maximum"
                    type="number"
                    placeholder="To"
                    value={newMax ? newMax : defaultMax}
                    onChange={(e) => priceFilterChange(e, filter?.type)}
                  />
                </Input.Group>
              </Row>
            </div>
          </div>
        </>
      );
    }
  };

  const onChangeSlider = (filterType, value) => {
    setNewMin(value[0]);
    setNewMax(value[1]);
    // handleSelectedFilter(null, filterType, value[0] + "-" + value[1]);
    // setTotalPrice("&price=" + value[0] + "-" + value[1]);
  };

  const applyPrice = (e, filterType) => {
    let handleNewMin = newMin;
    let handleNewMax = newMax;
    if (handleNewMin === "") {
      handleNewMin = defaultMin;
    }
    if (handleNewMax === "") {
      handleNewMax = defaultMax;
    }
    e.stopPropagation();
    handleSelectedFilter(null, filterType, handleNewMin + "-" + handleNewMax);
  };
  const resetPrice = (e, filterType) => {
    e.stopPropagation();
    setNewMin(defaultMin);
    setNewMax(defaultMax);
    handleSelectedFilter(null, filterType, defaultMin + "-" + defaultMax);
  };

  const priceFilterChange = (e, filterType) => {
    let currentMin = newMin;
    let currentMax = newMax;
    if (e.target.name === "minimum") {
      currentMin = e.target.value;
      setNewMin((prevMin) => {
        // if (currentMin < defaultMin) {
        //   return defaultMin;
        // }
        // if (!currentMin) {
        //   return defaultMin;
        // }
        return currentMin;
      });
    } else {
      currentMax = e.target.value;
      setNewMax((prevMax) => {
        // if (currentMax > defaultMax) {
        //   return defaultMax;
        // }
        // if (!currentMax) {
        //   return defaultMax;
        // }
        return currentMax;
      });
    }
    //setTotalPrice("&price=" + currentMin + "-" + currentMax);
    // console.log("totalPrice", totalPrice);

    // handleSelectedFilter(null, filterType, currentMin + "-" + currentMax);
  };

  // const handleSelectedFilter = (e, name, value) => {
  //
  //   let tempSelectedFilters = selectedFilters;
  //   let presentFlag = false;

  //   if (name !== "price") {
  //     if (e.target.checked === true) {
  //       // check if the filter is already in the selection behaviour array
  //       for (let index = 0; index < tempSelectedFilters.length; index++) {
  //         if (name === tempSelectedFilters[index].type) {
  //           presentFlag = true;
  //         }
  //       }

  //       // if present then push the value in the value array else push the whole new obj in the array
  //       if (presentFlag) {
  //         for (let index = 0; index < tempSelectedFilters.length; index++) {
  //           if (name === tempSelectedFilters[index].type) {
  //             tempSelectedFilters[index].value.push(value);
  //           }
  //         }
  //       } else {
  //         tempSelectedFilters.push({
  //           type: name,
  //           value: [value],
  //         });
  //       }
  //     } else {
  //       for (let index = 0; index < tempSelectedFilters.length; index++) {
  //         if (name === tempSelectedFilters[index].type) {
  //           tempSelectedFilters[index].value.splice(
  //             tempSelectedFilters[index].value.indexOf(value),
  //             1
  //           );

  //           // if the value array is empty then remove the whole obj from the array
  //           if (tempSelectedFilters[index].value.length === 0) {
  //             tempSelectedFilters.splice(index, 1);
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     // if the price is not presen then add the price obj in the array else update the same obj wihtout pushing a new obj
  //     let priceRangePresent = false;

  //     if (tempSelectedFilters.length) {
  //       tempSelectedFilters?.map((filter) => {
  //         if (name === filter.type) {
  //           filter.value = [value];
  //           priceRangePresent = true;
  //         }
  //       });
  //     }

  //     if (!priceRangePresent) {
  //       tempSelectedFilters.push({
  //         type: name,
  //         value: [value],
  //       });
  //     }
  //   }
  //   setSelectedFilters(tempSelectedFilters);
  //   handleFiltersQuery();
  // };

  const handleSelectedFiltersUrl = () => {
    let currentUrl = window.location.href;

    let urlToProcess = currentUrl.split("?")[1];

    if (urlToProcess) {
      let urlToProcessList = urlToProcess.split("&");

      // console.log("urlToProcessList", urlToProcessList);

      let tempSelectedFilters = [];

      urlToProcessList?.map((filter) => {
        let filterType = filter.split("=")[0];
        let filterValue = filter.split("=")[1]?.split("%2B");

        tempSelectedFilters.push({
          type: filterType,
          value: filterValue,
        });
      });

      // console.log("tempSelectedFilters", tempSelectedFilters);
      setSelectedFilters(tempSelectedFilters);
      getFilters(); // gettting updated filters
      fetchProducts(1, 15); // getting relevant products - when the user hits the url directly then always the first page will be shown and the products limit will be 15 as per the default grid view
    }
  };

  // console.log("selectionBehaviour", selectionBehaviour);

  const handleFiltersQuery = () => {
    let query = "";
    // let latestQuery = "&";
    for (let index = 0; index < selectedFilters.length; index++) {
      if (selectedFilters[index].value.length) {
        // if (index !== 0 && selectedFilters[index].latest_select === false) {
        //   query += "&";
        // }

        query += "&";
        query += selectedFilters[index].type + "=";
        query += selectedFilters[index].value.join("%2B");
        // if (selectionBehaviour[index].latest_select === false) {
        //   query += "&";
        //   query += selectionBehaviour[index].type + "=";
        //   query += selectionBehaviour[index].value.join("+");
        // } else {
        //   latestQuery += selectionBehaviour[index].type + "=";
        //   latestQuery += selectionBehaviour[index].value.join("+");
        // }
      }
    }

    appendToUrl(query + totalPrice);
  };

  const onSearchFilters = (value, filter) => {
    // results for search results of particular filter and allFiltersUpdated for updating the search results in all filters
    const results = [];
    const allFiltersUpdated = [];

    // setting the search results to the data
    filter?.data?.map((item) => {
      if (item?.name?.toLowerCase().includes(value.toLowerCase())) {
        results.push(item);
      }
    });

    // setting the updated data against the search in any filter
    allFilters?.map((item, index) => {
      if (item.type === filter.type) {
        allFiltersUpdated[index] = { ...item, data: results };
      } else {
        allFiltersUpdated[index] = {
          ...item,
          data: searchFiltersResult[index]?.data,
        };
      }
    });

    setFiltersSearchResult(allFiltersUpdated);
  };

  // const filterProducts = (e, name, value) => {
  //
  //   let flag = false;

  //   let tempFilters = Object.assign([], selectedFilters);

  //   if (e.target.checked === true) {
  //     if (!tempFilters.length) {
  //       tempFilters.push({ type: name, value: [value] });
  //     } else {
  //       for (let index = 0; index < tempFilters.length; index++) {
  //         if (name === tempFilters[index].type) {
  //           flag = true;
  //           tempFilters[index].value.push(value);
  //         }
  //       }
  //       if (flag === false) {
  //         tempFilters.push({
  //           type: name,
  //           value: [value],
  //         });
  //       }
  //     }
  //   } else {
  //     for (let index = 0; index < tempFilters.length; index++) {
  //       if (name === tempFilters[index].type) {
  //         tempFilters[index].value.splice(
  //           tempFilters[index].value.indexOf(value),
  //           1
  //         );
  //       }
  //     }
  //   }
  //   setSelectedFilters(Object.assign([], tempFilters));
  //   // console.log("Selected filters", tempFilters);
  //   FiltersQuery();
  // };

  const clearAllFilters = () => {
    // setOnLoadQuery("");
    let tempSelectedFilters = Object.assign([], selectedFilters);
    for (let i = 0; i < tempSelectedFilters.length; i++) {
      tempSelectedFilters[i].value = [];
    }
    setSelectedFilters(Object.assign([], tempSelectedFilters));
    setNewMin("");
    setNewMax("");
    setTotalPrice("");
    clearFilters();
  };
  //   useEffect(() => {
  //     console.log("this is the showFilterSearch", showFilterSearch);
  //     if (showFilterSearch.length) {
  //       displayFilters();
  //     }
  //   }, [showFilterSearch]);

  // useEffect(() => {
  //   if (filterType) {
  //     displayFilter();
  //   }
  // }, [filterType]);

  useEffect(() => {
    // display filters when the allfilters are updated
    displayFilter();
    setFiltersSearchResult(allFilters);
  }, [allFilters, filterType, showFilters]);

  return (
    <Drawer
      className="mobile-filters-drawer"
      title="Filter"
      placement="left"
      width="100%"
      onClose={onCloseFiltersDrawer}
      open={showFilters}
    >
      <Row className="mobile-filters-wrapper">
        <Col span={7} className="filter-tabs">
          <Row>
            {allFilters?.map((filter, index) => (
              <Col
                span={24}
                className={filterType === filter?.type && "selected-tab"}
                onClick={() => setFilterType(filter?.type)}
                key={index}
              >
                <Text className="filter-option-label-container">
                  {filter?.title}

                  {filterType === filter?.type &&
                    filter?.type !== "category" &&
                    filter?.type !== "price" &&
                    filter?.data?.length > 1 &&
                    selectedFilters.map(
                      (selectedFilter, index) =>
                        selectedFilter.type === filter?.type && (
                          <Text className="selected-filters-count" key={index}>
                            ({selectedFilter?.value?.length})
                          </Text>
                        )
                    )}
                </Text>
              </Col>
            ))}
          </Row>
        </Col>
        <Col span={17} className="filter-options-container">
          {displayFilter()}
        </Col>
      </Row>

      <Row className="filter-buttons-container">
        <Col span={12} className="reset-button-container">
          <Button onClick={() => clearAllFilters()}>Reset All</Button>
        </Col>
        <Col span={12} className="apply-button-container">
          <Button onClick={() => setShowFilters(false)}> Apply</Button>
        </Col>
      </Row>
    </Drawer>
  );
}
