"use client";
//? Library Imports ------------------------------------------------------------>
// import Image from "next/image";
import { useEffect, useState } from "react";
// import dynamic from "next/dynamic";
import {
  Col,
  Row,
  Typography,
  Space,
  Form,
  Modal,
  Input,
  Button,
  message,
  Image,
  Spin,
} from "antd";

//? Assets --------------------------------------------------------------------->
import { ExclamationCircleOutlined, RedoOutlined } from "@ant-design/icons";

//? Modules -------------------------------------------------------------------->
import { PhoneOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import PhoneInput from "react-phone-input-2";
import "./SoldOut.scss";
import NotifyForm from "./NotifyForm/NotifyForm";
import ImageGallery from "./ImageGallery/ImageGallery";
import ImagesCarousel from "../ProductImagesPanel/ImagesCarousel/ImagesCarousel";
import ProductDescription from "../ProductDetailPanel/ProductDescription/ProductDescription";
import DynamicButton from "@/components/Shared/Buttons/DynamicButton/DynamicButton";

//? Redux Reducers ------------------------------------------------------------->
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;
const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");
const defaultCountryFromCookies = Cookies.get("defaultCountry");

export default function SoldOut({ userAgent, ProductDetail, SelectedVariant }) {
  //? Hooks ---------------------------------------------------------------------->
  const { isMobile, isTablet, isDesktop } = userAgent;
  //? Redux States --------------------------------------------------------------->
  //? States --------------------------------------------------------------------->
  const [ShowNotifyModal, setShowNotifyModal] = useState(false);
  const [requiredFormState, setrequiredFormState] = useState(true);
  const [notifyUserLoader, setNotifyUserLoader] = useState(false);
  const [notifyUserSuccess, setNotifyUserSuccess] = useState(false);

  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    phoneNo: "",
  });

  const showNotificationModal = () => {
    setShowNotifyModal(false);
  };

  const notifyUser = async (data) => {
    setNotifyUserLoader(true);

    try {
      const body = {
        name: data?.full_name,
        email: data?.email,
        phone: data?.phoneNo,
        variant: ProductDetail?.variants[0]?.variant_id,
      };

      const payload = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };

      const Notify = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/add_product_to_notify_by_user?storefront=23`,
        payload
      );

      if (!Notify.ok) {
        throw new Error("NotifyUser API Failed!");
      }

      // const NotifyResponse = await Notify.json();

      if (Notify?.ok) {
        // console.log("Notification Enabled Successful");
        // setFormData((prevState) => ({
        //   ...prevState,
        //   phoneNo: "",
        //   full_name: "",
        //   email: "",
        // }));
        setNotifyUserLoader(false);
        setNotifyUserSuccess(true);
        // setShowNotifyModal(false);
        // message.success("Notification Enabled Successfully!");
      } else {
        setNotifyUserLoader(false);
        setNotifyUserSuccess(false);

        // console.log("Notification Failed");
        // message.success("Notification Enabled Successfully!");
      }
    } catch (error) {
      console.error("Error:", error.message);
      setNotifyUserLoader(false);
      setNotifyUserSuccess(false);
    }
  };

  const handleFormChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (formData?.phoneNo === "" && formData?.email === "") {
      setrequiredFormState(true);
    } else {
      setrequiredFormState(false);
    }
  }, [formData]);

  //? Life Cycles ---------------------------------------------------------------->

  return (
    <>
      <Col
        className="SoldOut"
        //   style={{ height: "400px", maxHeight: "400px", overflow: "scroll" }}
      >
        <Row gutter={isDesktop ? [0, 20] : [0, 0]}>
          {/* <Col span={24} style={{ background: "#F8D7DA" }} className="padding10">
          <Space align="center">
            <ExclamationCircleOutlined
              style={{
                fontSize: "16px",
                color: "#DC3545",
              }}
            />
            <Text className={isDesktop ? "font14" : ""}>
              We are sorry, this product is out of stock.
            </Text>
          </Space>
        </Col> */}
          <Col span={24} className={!isDesktop ? "padding10" : ""}>
            <Row
              justify="space-between"
              // gutter={[0, 20]}
            >
              <Col
                //   flex="58%"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 14 }}
                lg={{ span: 14 }}
                xl={{ span: 13 }}
                className={
                  isDesktop
                    ? "backgroundLight padding20 paddingRight30"
                    : "backgroundDark padding0"
                }
              >
                <Row
                  justify="space-between"
                  gutter={isMobile ? [0, 0] : [0, 20]}
                >
                  {/* <Col flex="28%"> */}
                  <Col
                    //   span={9}
                    xs={{ span: 0 }}
                    sm={{ span: 0 }}
                    md={{ span: 9 }}
                    lg={{ span: 9 }}
                  >
                    <ImageGallery
                      userAgent={userAgent}
                      ProductDetail={ProductDetail}
                    />
                  </Col>

                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 0 }}>
                    <ImagesCarousel
                      // settings={settings}
                      Images={ProductDetail?.images}
                    />
                  </Col>
                  {/* <Col flex="68%"> */}
                  <Col
                    //   span={14}
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 14 }}
                    lg={{ span: 14 }}
                    className={
                      !isDesktop
                        ? "padding10 notife-me-container"
                        : "notife-me-container"
                    }
                  >
                    <NotifyForm
                      userAgent={userAgent}
                      ProductDetail={ProductDetail}
                      hideNotifyForm
                    />
                  </Col>
                </Row>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 9 }}
                lg={{ span: 9 }}
                xl={{ span: 10 }}
                className="backgroundLight padding10"
                style={{
                  height: "475px",
                  maxHeight: "475px",
                  overflow: "scroll",
                  marginTop: "-10px",
                }}
              >
                <ProductDescription
                  userAgent={userAgent}
                  ProductDetail={ProductDetail}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Modal
        footer={null}
        // open={true}
        open={ShowNotifyModal}
        title="Notify On Restock"
        // className="NotifyModal"
        wrapClassName="NotifyModal"
        onCancel={showNotificationModal}
      >
        <Col span={24}>
          <Form
            size="large"
            className="notify_form"
            name="notify_form"
            initialValues={{
              remember: true,
            }}
            onFinish={notifyUser}
            // onFinishFailed={onLoginFailed}
            autoComplete="off"
            style={{
              width: "100%",
            }}
          >
            <Col>
              <Form.Item
                name="full_name"
                style={{ margin: "10px 0px" }}
                rules={[
                  { required: true, message: "Please enter a valid name!" },
                  // { min: 3, message: "Too Short!" },
                  { max: 40, message: "Too Long!" },
                  {
                    pattern: /^[aA-zZ\s]+$/,
                    message: "Only alphabets are allowed for this field ",
                  },
                ]}
              >
                <Input
                  placeholder="Enter Full Name"
                  value={formData?.full_name}
                  onChange={(e) => {
                    handleFormChange("full_name", e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                name="email"
                style={{ margin: "10px 0px" }}
                rules={[
                  {
                    required: requiredFormState,
                    message: "Please enter a valid email!",
                  },
                  {
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email!",
                  },
                ]}
              >
                <Input
                  placeholder="John@example.com"
                  value={formData?.email}
                  onChange={(e) => {
                    handleFormChange("email", e.target.value);
                  }}
                />
              </Form.Item>

              <Form.Item
                //   className={isMobile || isTablet ? "marginBottom10" : ""}
                name="phoneNo"
                rules={[
                  {
                    required: requiredFormState,
                  },
                ]}
              >
                <PhoneInput
                  onChange={(e) => {
                    handleFormChange("phoneNo", e);
                  }}
                  country={"pk"}
                  value={formData?.phoneNo}
                  // onChange={(phone) => this.setState({ phone })}
                  // country={SelectedCountry?.flag?.toLowerCase()}
                  // value={userInformation?.shipping_address?.phone}
                />
              </Form.Item>
            </Col>
            <Form.Item>
              <Button
                block
                type="primary"
                htmlType="submit"
                style={{
                  background: notifyUserSuccess ? "#008605" : "black",
                  color: "white",
                  fontSize: "12px",
                  width: "98%",
                  margin: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "18px",
                  borderRadius: "8px",
                }}
              >
                {notifyUserSuccess ? (
                  <Image
                    height="auto"
                    width={20}
                    src="/notified.png"
                    style={{ marginRight: "10px", marginBottom: "4px" }}
                  />
                ) : !notifyUserLoader ? (
                  <Image
                    height="auto"
                    width={20}
                    src="/notify.png"
                    style={{ marginRight: "10px", marginBottom: "4px" }}
                    preview={false}
                  />
                ) : (
                  <Spin
                    indicator={
                      <RedoOutlined
                        spin
                        className="spinner-redo-white"
                        fontSize={"20px"}
                        style={{
                          collor: "white",
                        }}
                      />
                    }
                  />
                )}
                {/* <img
                  height={16}
                  style={{ marginRight: "10px" }}
                  src="/timer.png"
                  alt="404"
                /> */}
                <Text className="font13 uppercase" style={{ color: "white" }}>
                  {notifyUserLoader
                    ? "Notify..."
                    : notifyUserSuccess
                    ? "Restock Request Received!"
                    : "Notify on Restock"}
                </Text>
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Modal>
      <Col
        // lg={{ span: 0 }}
        // xs={{ span: 24 }}
        span={isDesktop ? 0 : 24}
        className="backgroundLight padding5 product-buying-bottom-nav"
        style={{
          left: "0px",
          right: "0px",
          zIndex: "100",
          bottom: "40px",
          position: "fixed",
        }}
      >
        {ProductDetail?.sold_out ? (
          <Row
            style={{
              border: "1px solid #000000",
              marginBottom: "6px",
            }}
          >
            <Col
              flex="30%"
              onClick={(e) => {
                // window.Trengo.Api.Widget.open("chat");
                window.FreshworksWidget("open");
                document.querySelector("#freshworks-container").style.display =
                  "block";
              }}
            >
              {/* <Link href="https://wa.me/+923158337822?text=Can you please help me with"> */}
              <DynamicButton
                name="HELP"
                style={{
                  // width: "95px",
                  border: "none",
                  height: "100%",
                  minHeight: "34px",
                  color: "#FFFFFF",
                  fontSize: "12px",
                  background: "black",
                  borderRadius: "0px",
                }}
                icon={
                  <PhoneOutlined style={{ fontSize: "14px", color: "white" }} />
                }
              />
              {/* </Link> */}
            </Col>

            <Col
              flex="70%"
              className="padding5 dispatch-container"
              style={{ marginLeft: "5px" }}
            >
              <Space align="center">
                <Text className={!isDesktop ? "font11" : ""} textAlign="center">
                  This product is out of stock
                </Text>
              </Space>
            </Col>
          </Row>
        ) : null}
      </Col>
      {ProductDetail?.sold_out && !isDesktop && (
        <Col
          style={{
            backgroundColor: "#FFF",
            position: "fixed",
            bottom: "0px",
            width: "100%",
            height: "45px",
            textAlign: "center",
            zIndex: "100",
          }}
        >
          <Button
            variant="contain"
            style={{
              background: notifyUserSuccess ? "#008605" : "black",
              color: "white",
              fontSize: "12px",
              width: "98%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "18px",
            }}
            onClick={() => {
              setShowNotifyModal(true);
            }}
          >
            {notifyUserSuccess ? (
              <Image
                height="auto"
                width={18}
                src="/notified.png"
                style={{ marginRight: "10px" }}
              />
            ) : (
              !notifyUserLoader && (
                <img
                  height={16}
                  style={{ marginRight: "10px" }}
                  src="/timer.png"
                  alt="404"
                />
              )
            )}

            <Text className="font13 uppercase" style={{ color: "white" }}>
              {notifyUserSuccess
                ? "Restock Request Received!"
                : "Notify on Restock"}
            </Text>
          </Button>
        </Col>
      )}
    </>
  );
}
