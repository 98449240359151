import React, { useRef, useState } from "react";
import { Col, Row, Image } from "antd";
import { ZoomInOutlined } from "@ant-design/icons";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./ImagesGrid.scss";
// import Image from "next/image";

export default function ImagesGrid({ ProductDetail, isMobile }) {
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [imageLoaded, setImageLoaded] = useState({});
  const [imageSrcs, setImageSrcs] = useState(
    ProductDetail?.images?.reduce(
      (acc, img, index) => ({
        ...acc,
        [index]: img?.cdn_link + "%3Fwidth%3D640",
      }),
      {}
    )
  );

  const handleMouseMove = (event) => {
    const rect = event.target.getBoundingClientRect();
    let x = event.clientX - rect.left;
    let y = event.clientY - rect.top;

    const bufferPercentage = 20;
    const minX = rect.width * (bufferPercentage / 100);
    const maxX = rect.width * (1 - bufferPercentage / 100);
    const minY = rect.height * (bufferPercentage / 100);
    const maxY = rect.height * (1 - bufferPercentage / 100);

    x = Math.max(minX, Math.min(x, maxX));
    y = Math.max(minY, Math.min(y, maxY));

    setCursorPosition({ x, y });
  };

  const onImageLoad = (index) => {
    setImageLoaded((prev) => ({ ...prev, [index]: true }));
  };
  const handleImageError = (index) => {
    setImageSrcs((prevSrcs) => ({
      ...prevSrcs,
      [index]: ProductDetail?.images[index]?.cdn_link,
    }));
  };

  return (
    <>
      <Col className="ImagesGrid">
        <Row style={{ display: "flex", flexWrap: "wrap" }} gutter={[5, 5]}>
          {ProductDetail?.images?.map((img, index) => (
            <Col
              key={index}
              className="ImgContainer"
              style={{ position: "relative", overflow: "hidden" }}
              flex={ProductDetail?.images?.length > 1 ? "50%" : "100%"}
            >
              {!imageLoaded[index] && (
                <div
                  style={{
                    width: "100%",
                    height: "470px",
                    backgroundColor: "#f2f3f7",
                    position: "relative",
                    // marginLeft: "5px",
                    top: 0,
                    left: 0,
                    zIndex: 1,
                  }}
                />
              )}
              <div
                className="enlargeButton"
                style={{
                  position: "absolute",
                  cursor: "pointer",
                  left: "10px",
                  top: "10px",
                  zIndex: 1,
                }}
                onClick={() =>
                  NativeFancybox.show(
                    ProductDetail?.images?.map((img) => ({
                      src: img?.cdn_link,
                      type: "image",
                    })),
                    {
                      startIndex: index,
                    }
                  )
                }
              >
                <ZoomInOutlined className="iconStyle" />
                <span className="hoverText">Click to enlarge</span>
              </div>

              <div
                className="imageWrapper"
                style={{
                  width: "100%",
                  height: "100%",
                  overflow: "hidden",
                }}
                onMouseEnter={() => setHoveredImageIndex(index)}
                onMouseLeave={() => setHoveredImageIndex(null)}
                onMouseMove={handleMouseMove}
              >
                <Image
                  width="100%"
                  height="100%"
                  alt={ProductDetail?.title || "Default Image"}
                  title={ProductDetail?.title || "Default Image"}
                  src={imageSrcs[index]}
                  onLoad={() => onImageLoad(index)}
                  onError={() => handleImageError(index)}
                  style={{
                    transform:
                      hoveredImageIndex === index ? "scale(1.25)" : "scale(1)",
                    transformOrigin: `${cursorPosition.x}px ${cursorPosition.y}px`,
                    transition: "transform .2s",
                    opacity: imageLoaded[index] ? 1 : 0,
                    top: 0,
                    left: 0,
                    display: !imageLoaded[index] ? "none" : "block",
                  }}
                  preview={false}
                />
              </div>
            </Col>
          ))}
        </Row>
      </Col>
    </>
  );
}
