"use client";
//? Library Imports ------------------------------------------------------------>
import { Row, Col, message, Typography } from "antd";
import { useCallback, useState } from "react";
import Cookies from "js-cookie";

//? Modules -------------------------------------------------------------------->
import Loader from "@/components/Shared/Loader/Loader";
// import MediaQueryHook from "@/hooks/useMediaQuery/MediaQueryHook";
import DynamicButton from "@/components/Shared/Buttons/DynamicButton/DynamicButton";
import ProductCard from "@/components/Shared/ProductsCarousel/ProductCard/ProductCard";
import ProductsCarousel from "@/components/shared/ProductsCarousel/ProductsCarousel";
import "../../../../components/Homepage/HomePage.scss";
//? Library Constants ---------------------------------------------------------->
const { Text } = Typography;

export default function SimilarProducts({
  handle,
  userAgent,
  RelatedProducts,
}) {
  //? Hooks -------------------------------------------------------------------->
  // const { isMobile, isTablet, isDesktop } = MediaQueryHook();
  const { isMobile, isTablet, isDesktop } = userAgent;
  const defaultCurrencyIdFromCookies = Cookies.get("defaultCurrencyId");

  //? States --------------------------------------------------------------------->
  const [Products, setProducts] = useState(RelatedProducts?.results);
  const [ActivePage, setActivePage] = useState(2);
  const [ShowLoader, setShowLoader] = useState(false);
  // const [HasMoreData, setHasMoreData] = useState(
  //   RelatedProducts?.next || false
  // );
  const TotalProducts = RelatedProducts?.count;
  const TotalPages = Math.ceil(TotalProducts / 5);
  const HasMoreData = ActivePage < TotalPages ? true : false;

  //? Functions ------------------------------------------------------------------>
  const fetchSimilarProducts = useCallback(async () => {
    // setShowLoader(true);
    // const res = await fetch(
    //   process.env.NEXT_PUBLIC_BACKEND +
    //     `/storefront/product/related_products/${handle}?limit=5&page=${ActivePage}&storefront=23`
    // );

    // if (!res.ok) {
    //   message.error("RelatedProducts Api Failed!");
    //   throw new Error("RelatedProducts Api Failed!");
    // }
    // const data = await res.json();
    // setProducts((prevProducts) => [...prevProducts, ...data?.results]);
    // // setHasMoreData(data?.next);
    // setActivePage((prevPage) => prevPage + 1);
    // setShowLoader(false);

    try {
      setShowLoader(true);
      const res = await fetch(
        process.env.NEXT_PUBLIC_BACKEND +
          `/storefront/product/related_products/${handle}?limit=${
            isMobile ? 16 : 5
          }&page=${ActivePage}&storefront=${defaultCurrencyIdFromCookies}`
      );
      if (!res.ok) {
        // message.error("RelatedProducts Api Failed!");
        throw new Error("RelatedProducts Api Failed!");
      }

      const data = await res.json();
      setProducts((prevProducts) => [...prevProducts, ...data?.results]);
      setActivePage((prevPage) => prevPage + 1);
      setShowLoader(false);
      return data;
    } catch (error) {
      // message.error("RelatedProducts Api Failed!");
      console.error("Error fetching data:", error.message);
      return null;
    }
  }, [ActivePage, handle]);

  // console.log("RelatedProducts--------->", RelatedProducts);
  // console.log("TotalPages--------->", TotalPages);
  // console.log("HasMoreData--------->", HasMoreData);

  return (
    Products?.length && (
      <Col
        span={24}
        className={
          isMobile || isTablet
            ? "padding10"
            : "backgroundLight margin30 padding30"
        }
      >
        {!isMobile && (
          <>
            <Text
              className={isMobile || isTablet ? "font14Bold" : "font16Bold"}
            >
              Similar Products
            </Text>

            <Row
              justify="start"
              className={
                isMobile ? "flex width100" : "flex width100 marginTop30"
              }
              style={{ gap: "1%" }}
            >
              {Products?.map((product, index) => (
                <Col
                  key={index}
                  style={{
                    padding: "0",
                    margin: "0 0 30px 0",
                    width: isMobile || isTablet ? "49%" : "19%",
                  }}
                >
                  <ProductCard product={product} userAgent={userAgent} />
                </Col>
              ))}
            </Row>

            {ShowLoader && (
              <Col span={24} className="marginTop40 flexCenter">
                <Col span={6}>
                  <Loader size="large" />
                </Col>
              </Col>
            )}

            {HasMoreData && (
              <Col span={24} className="marginTop5 flexCenter">
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 8 }}
                  lg={{ span: 6 }}
                >
                  <DynamicButton
                    type="primary"
                    name="Load More"
                    property="danger"
                    className="primary_btn_outline"
                    function={() => fetchSimilarProducts()}
                  />
                </Col>
              </Col>
            )}
          </>
        )}

        {isMobile && (
          <div
            className="product-carousel-main"
            style={{
              margin: "auto",
              maxWidth: "100%",
            }}
          >
            <ProductsCarousel
              data={Products}
              title="Similar Products"
              userAgent={{ isMobile, isTablet, isDesktop }}
              // type={"homepage"}
            />
          </div>
        )}
      </Col>
    )
  );
}
